import fetchAPI from '../fetchAPI';

interface callGetSrtTransactionLogsProps {
  pageNumber?: number;
  pageSize?: number;
  companyName?: string;
  startDate?: string;
  endDate?: string;
  sortBy?: string | string[];
}

export const callGetSrtTransactionLogs = async (
  props: callGetSrtTransactionLogsProps
): Promise<iResponse<Pagination<SrtTransactionsLogs>>> => {
  const { pageNumber, pageSize, companyName, startDate, endDate, sortBy } = props;

  const queryParams = new URLSearchParams();
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (startDate) {
    queryParams.append('startDate', startDate);
  }
  if (endDate) {
    queryParams.append('endDate', endDate);
  }
  if (sortBy) {
    if (Array.isArray(sortBy)) {
      sortBy.forEach((sort) => queryParams.append('sortBy', sort));
    } else {
      queryParams.append('sortBy', sortBy);
    }
  }

  if (companyName) {
    queryParams.append('companyName', companyName);
  }
  const queryString = queryParams.toString();

  const response: iResponse<Pagination<SrtTransactionsLogs>> = await fetchAPI({
    method: `GET`,
    route: `srt-transaction-log`,
    queryString
  });

  return response;
};

export default callGetSrtTransactionLogs;
