import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Box, Stack } from '@mui/material';
import { ReactComponent as Salesrook } from '@assets/svg/salesrook.svg';
import { ReactComponent as Error404 } from '@assets/svg/404.svg';
import { TextView } from '@atoms';
import { materialTheme } from '@particles';

const Errors404: React.FC<RouteComponentProps> = () => (
  <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
    <Salesrook style={{ marginTop: 36 }} />
    <TextView $fontSize={24} mt={8}>
      Access Denied
    </TextView>
    <Stack direction={'row'} mt={3} mb={8}>
      <TextView $fontWeight={400}>
        Sorry, but you do not have permission to accees this page. You can go back to
      </TextView>
      <TextView
        $fontWeight={700}
        $fontColor={materialTheme.palette.secondary.main}
        ml={0.6}
        onClick={() => {
          navigate(-1);
        }}
      >
        previous page
      </TextView>
    </Stack>
    <Error404 />
  </Box>
);

export default Errors404;
