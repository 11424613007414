import { start } from 'repl';
import fetchAPI from '../../fetchAPI';

interface callGetCoreDetailedListProps {
  locationId?: string;
  status?: string;
  companyName?: string;
  startDate?: string | null;
  endDate?: string | null;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string | string[];
}

export const callGetCoreDetailedList = async (
  props: callGetCoreDetailedListProps
): Promise<iResponse<Pagination<CoreLocation>>> => {
  const { pageNumber, pageSize, companyName, sortBy, status, locationId, startDate, endDate } =
    props;

  const queryParams = new URLSearchParams();
  if (locationId) {
    queryParams.append('locationId', locationId.toString());
  }
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (companyName) {
    queryParams.append('companyName', companyName);
  }
  if (status) {
    queryParams.append('status', status);
  }
  if (endDate && endDate != null) {
    queryParams.append('endDate', endDate);
  }
  if (startDate && startDate != null) {
    queryParams.append('startDate', startDate);
  }
  if (sortBy) {
    if (Array.isArray(sortBy)) {
      sortBy.forEach((sort) => queryParams.append('sortBy', sort));
    } else {
      queryParams.append('sortBy', sortBy);
    }
  }

  const queryString = queryParams.toString();
  const response: iResponse<Pagination<CoreLocation>> = await fetchAPI({
    method: `GET`,
    route: `core/detailed-list`,
    queryString
  });

  return response;
};

export default callGetCoreDetailedList;
