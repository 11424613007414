import React, { useEffect, useState } from 'react';
import { TextView, TextField } from '@atoms';
import { Box, Card, Grid, LinearProgress } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { callGetMarkupValues, callUpdateMarupValues } from '@api';

// Define TypeScript interfaces for better type safety

interface MarkupFormData {
  [key: string]: {
    value: number;
    price?: number;
  };
}

const INITIAL_FORM_DATA: MarkupFormData = {
  ai_processing: { value: 0, price: 0 },
  ai_processing_low: { value: 0, price: 0 },
  whatsapp_inbound: { value: 0, price: 0 },
  whatsapp_outbound: { value: 0, price: 0 },
  whatsapp: { value: 0 },
  inbound_sms: { value: 0 },
  outbound_sms: { value: 0 },
  inbound_call: { value: 0 },
  outbound_call: { value: 0 },
  twilio_other: { value: 0 },
  reapit: { value: 0 }
};

// Reusable TextField component with common props
const FormTextField: React.FC<{
  value: number;
  onChange: (value: number) => void;
  label: string;
  fullWidth?: boolean;
}> = ({ value, onChange, label, fullWidth = false }) => (
  <TextField
    value={value}
    size="small"
    type="number"
    onChange={(e) => onChange(Number(e.target.value))}
    label={label}
    labelSize={14}
    labelWeight={700}
    name=""
    fullWidth={fullWidth}
  />
);

// Card Section Component
const CardSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <Grid item mt={2}>
    <TextView>{title}</TextView>
    <Card sx={{ p: 2, backgroundColor: '#f3f6f4', mt: 1 }}>
      <Grid spacing={2} container direction="column">
        {children}
      </Grid>
    </Card>
  </Grid>
);

const SuperAdminMarkupMargins: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<MarkupFormData>(INITIAL_FORM_DATA);

  const handleValueChange = async (type: string, field: 'value' | 'price', newValue: number) => {
    setFormData((prev) => ({
      ...prev,
      [type]: { ...prev[type], [field]: newValue }
    }));

    await callUpdateMarupValues({
      type,
      [field === 'value' ? 'muvalue' : 'price']: newValue
    });
  };

  useEffect(() => {
    const fetchMarkupValues = async () => {
      setLoading(true);
      try {
        const { data, code } = await callGetMarkupValues();
        if (code === 200 && data?.data) {
          const newFormData = { ...INITIAL_FORM_DATA };
          data?.data?.forEach((item: MarkUp) => {
            if (newFormData[item.type]) {
              newFormData[item.type] = {
                value: item.muvalue,
                price: item.price || newFormData[item.type].price
              };
            }
          });
          setFormData(newFormData);
        }
      } catch (error) {
        console.error('Error fetching markup values:', error);
      }
      setLoading(false);
    };

    fetchMarkupValues();
  }, []);

  return (
    <Box sx={{ m: 5 }}>
      {loading && <LinearProgress />}
      <TextView display="initial" $fontSize={30}>
        Markup Margins
      </TextView>

      <Grid container spacing={4} mt={2}>
        <Grid item>
          <CardSection title="AI Processing">
            <Grid item direction="row" display="flex">
              <FormTextField
                value={formData.ai_processing.value}
                onChange={(value) => handleValueChange('ai_processing', 'value', value)}
                label="High AI Processing"
              />
              <Box sx={{ ml: 1, width: 100 }}>
                <FormTextField
                  value={formData.ai_processing.price || 0}
                  onChange={(value) => handleValueChange('ai_processing', 'price', value)}
                  label="Price ($)"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item direction="row" display="flex">
              <FormTextField
                value={formData.ai_processing_low.value}
                onChange={(value) => handleValueChange('ai_processing_low', 'value', value)}
                label="Low AI Processing"
              />
              <Box sx={{ ml: 1, width: 100 }}>
                <FormTextField
                  value={formData.ai_processing_low.price || 0}
                  onChange={(value) => handleValueChange('ai_processing_low', 'price', value)}
                  label="Price ($)"
                  fullWidth
                />
              </Box>
            </Grid>
          </CardSection>

          <CardSection title="WABA">
            <Grid item>
              <FormTextField
                value={formData.whatsapp.value}
                onChange={(value) => handleValueChange('whatsapp', 'value', value)}
                label="WhatsApp Template"
              />
            </Grid>
            <Grid item direction="row" display="flex">
              <FormTextField
                value={formData.whatsapp_inbound.value}
                onChange={(value) => handleValueChange('whatsapp_inbound', 'value', value)}
                label="WhatsApp Inbound"
              />
              <Box sx={{ ml: 1, width: 100 }}>
                <FormTextField
                  value={formData.whatsapp_inbound.price || 0}
                  onChange={(value) => handleValueChange('whatsapp_inbound', 'price', value)}
                  label="Price ($)"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item direction="row" display="flex">
              <FormTextField
                value={formData.whatsapp_outbound.value}
                onChange={(value) => handleValueChange('whatsapp_outbound', 'value', value)}
                label="WhatsApp Outbound"
              />
              <Box sx={{ ml: 1, width: 100 }}>
                <FormTextField
                  value={formData.whatsapp_outbound.price || 0}
                  onChange={(value) => handleValueChange('whatsapp_outbound', 'price', value)}
                  label="Price ($)"
                  fullWidth
                />
              </Box>
            </Grid>
          </CardSection>
        </Grid>

        <CardSection title="Twilio">
          {['inbound_call', 'outbound_call', 'inbound_sms', 'outbound_sms', 'twilio_other'].map(
            (type) => (
              <Grid item key={type}>
                <FormTextField
                  value={formData[type].value}
                  onChange={(value) => handleValueChange(type, 'value', value)}
                  label={type
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                />
              </Grid>
            )
          )}
        </CardSection>

        <CardSection title="Negotiator">
          <Grid item>
            <FormTextField
              value={formData.reapit.value}
              onChange={(value) => handleValueChange('reapit', 'value', value)}
              label="Negotiator"
            />
          </Grid>
        </CardSection>
      </Grid>
    </Box>
  );
};

export default SuperAdminMarkupMargins;
