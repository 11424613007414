import fetchAPI from '../fetchAPI';

export const callUpdateMarupValues = async (data: any): Promise<iResponse<MarkUp>> => {
  const response: iResponse<MarkUp> = await fetchAPI({
    method: `PUT`,
    route: `markup`,
    data
  });

  return response;
};

export default callUpdateMarupValues;
