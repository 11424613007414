import fetchAPI from '../fetchAPI';

interface callGetLicenseLatestProps {
  companyId?: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string | string[];
}

export const callGetLicenseLatest = async ({
  pageNumber,
  pageSize,
  companyId,
  sortBy
}: callGetLicenseLatestProps): Promise<iResponse<Pagination<License>>> => {
  const queryParams = new URLSearchParams();
  if (companyId) {
    queryParams.append('companyId', companyId);
  }
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (sortBy) {
    if (Array.isArray(sortBy)) {
      sortBy.forEach((sort) => queryParams.append('sortBy', sort));
    } else {
      queryParams.append('sortBy', sortBy);
    }
  }

  const queryString = queryParams.toString();

  const response: iResponse<Pagination<License>> = await fetchAPI({
    method: `GET`,
    route: `company/license-latest`,
    queryString
  });

  return response;
};

export default callGetLicenseLatest;
