const columns: TableColumn[] = [
  {
    Header: ``,
    accessor: `type`,
    dbKey: `type`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
    {
    Header: `Account`,
    accessor: `account`,
    dbKey: `account`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Name`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Email`,
    accessor: `email`,
    dbKey: `email`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Phone`,
    accessor: `phone`,
    dbKey: `phone`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
];

export default columns;
