import * as React from 'react';
import { TextView } from '@atoms';
import { Box, Stack } from '@mui/material';

interface CardProps {
  title: string;
  subtitle?: string;
  bgColor?: string;
  fontColor?: string;
  count?: string | number;
  icon?: React.ReactNode;
}

const CommonCard = (props: CardProps) => {
  const { title, count, icon, bgColor = 'rgba(36, 36, 35, 0.08)', fontColor = '#242423' } = props;
  return (
    <Stack direction={'row'} sx={{ backgroundColor: bgColor, borderRadius: 1, p: 2, height: 100 }}>
      <Box flex="1">
        <TextView $fontWeight={400} $fontColor={fontColor}>
          {title}
        </TextView>
        {count && (
          <TextView $fontSize={32} $fontColor={fontColor}>
            {count}
          </TextView>
        )}
      </Box>
      {icon}
    </Stack>
  );
};

export default CommonCard;
