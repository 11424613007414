import fetchAPI from '../../fetchAPI';

export const callDeleteCoreOfficeAddress = async ({
  locationId,
  officeAddressId
}: {
  locationId: string;
  officeAddressId: string;
}): Promise<iResponse<Pagination<CoreOfficeAddress>>> => {
  const response: iResponse<Pagination<CoreOfficeAddress>> = await fetchAPI({
    method: `DELETE`,
    route: `core/location/${locationId}/offices-address/${officeAddressId}`
  });

  return response;
};

export default callDeleteCoreOfficeAddress;
