import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Button, TextView } from '@atoms';
import { DateRangePicker, Range } from 'react-date-range';
import Popover from '@mui/material/Popover';
import EventIcon from '@mui/icons-material/EventOutlined';
import { DateTime } from 'luxon';
import { materialTheme } from '@particles';

interface HeaderProps {
  title: string;
  start: Date;
  end: Date;
  csvDownload?: boolean;
  onSelectRange?: (start: Date, end: Date) => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { start, end, title, onSelectRange, csvDownload } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [downloading, setDownloading] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [range, setRange] = useState<Range[]>([
    {
      startDate: start,
      endDate: end,
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const date = new Date();
    const start = range[0]?.startDate ? range[0].startDate : date;
    const end = range[0]?.endDate ? range[0].endDate : date;

    const newEnd = new Date(end);
    newEnd.setHours(23);
    newEnd.setMinutes(59);
    newEnd.setSeconds(59);
    onSelectRange && onSelectRange(start, newEnd);
  }, [range]);

  const downloadFile = async () => {
    setDownloading(true);
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;

    const startDate = DateTime.fromJSDate(range[0].startDate ?? new Date()).toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });
    const endDate = DateTime.fromJSDate(range[0].endDate ?? new Date()).toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });

    const urlApi = `${apiBase}/export-csv?fromDate=${startDate}&toDate=${endDate}`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}` ?? '',
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'DashboardReport.xlsx');
    document.body.appendChild(link);
    link.click();
    setDownloading(false);
  };

  return (
    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
      <TextView display={'initial'} $fontSize={30}>
        {title}
      </TextView>
      <Box sx={{ flex: 1 }} />
      <TextView $fontWeight={400} $fontSize={14} mr={1}>
        Filters
      </TextView>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <DateRangePicker
          onChange={(item) => setRange([item.selection])}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={range}
          direction="horizontal"
        />
      </Popover>

      <Button
        aria-describedby={id}
        sx={{ paddingLeft: 5, color: materialTheme.palette.text.primary, mr: 2 }}
        startIcon={<EventIcon />}
        variant={'outlined'}
        onClick={handleClick}
      >
        <TextView $fontWeight={400}>
          {DateTime.fromJSDate(range[0].startDate ? range[0]?.startDate : new Date()).toFormat(
            'LLLL, dd yyyy'
          )}
          {` - `}
          {DateTime.fromJSDate(range[0].endDate ? range[0]?.endDate : new Date()).toFormat(
            'LLLL, dd yyyy'
          )}
        </TextView>
      </Button>
      {csvDownload && <Button label="Download" onClick={downloadFile} loading={downloading} />}
    </Box>
  );
};
export default Header;
