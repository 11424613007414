import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callGetUnifiedUser, callGetUnifiedUserStatistics } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, TextField, TextView } from '@atoms';
import { RouteComponentProps } from '@reach/router';
import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import CardList from './commonCard';

const UnifiedUsers: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      unifiedUsersPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [unifedUsers, setUnifedUsers] = useState<Pagination<UnifedUser>>();
  const [unifedUserStatistics, setUnifedUserStatistics] = useState<UnifedUserStatistics>();
  const [search, setSearch] = useState<string>();
  const [selectType, setSelectType] = useState<'core' | 'negotiator' | 'all'>('all');

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetUnifiedUser({page, perPage, sortBy: orderBy, name: search, type: selectType});
    if (code == 200) {
      setUnifedUsers(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      const { data, code } = await callGetUnifiedUserStatistics();
      if (code == 200) {
        setUnifedUserStatistics(data);
      }
      setLoading(false);
    };
    api();
  }, []);

  useEffect(() => {
    api();
  }, [page, perPage, search, orderBy, selectType]);

  const rowData: TableRow[] = unifedUsers ? generateRows(unifedUsers.data) : [];

  const handleChange = (event: SelectChangeEvent) => {
    setSelectType(event.target.value as 'core' | 'negotiator' | 'all');
  };

  return (
    <Box m={4}>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextView display={'initial'} $fontSize={30}>
          Unified Users
        </TextView>
        <Box sx={{ flex: 1 }} />
      </Box>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}
      <CardList statistics={unifedUserStatistics} />
      <Stack direction={'row'} sx={{ mb: 2 }}>
        <TextField
          label={'Search Name'}
          name={'Search Name'}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Stack mt={1.5} ml={2}>
          <TextView $fontWeight={400} $fontSize={14}>
            User Type
          </TextView>
          <Select
            value={selectType}
            onChange={handleChange}
            displayEmpty
            size={'small'}
            sx={{ backgroundColor: '#ffffff', fontSize: 14, mt: 1 }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem key={0} value={'all'}>
              All
            </MenuItem>
            <MenuItem key={1} value={'core'}>
              Core
            </MenuItem>
            <MenuItem key={2} value={'negotiator'}>
              Negotiator
            </MenuItem>
          </Select>
        </Stack>
        <Stack mt={1.5} ml={2}>
          <TextView $fontWeight={400} $fontSize={14}>
            Accounts
          </TextView>
          <Select
            value={selectType}
            onChange={handleChange}
            displayEmpty
            size={'small'}
            sx={{ backgroundColor: '#ffffff', fontSize: 14, mt: 1 }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem key={0} value={'all'}>
              All
            </MenuItem>
          </Select>
        </Stack>
      </Stack>

      <Table
        name="unified-users"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={unifedUsers}
      />

    </Box>
  );
};

export default UnifiedUsers;
