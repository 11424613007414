import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callDeleteCoreOfficeAddress, callGetCoreOfficeAddress } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, Button, Text, TextView } from '@atoms';
import { Stack } from '@mui/material';
import AddOffice from './addOffices';
import UploadOfficesCSV from './uploadOfficesCSV';

const CoreOffices: React.FC<{ locationId: string }> = ({ locationId }) => {
  const {
    state: {
      coreOfficesPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);
  const [openOfficeAddress, setOfficeAddress] = useState<boolean>(false);
  const [selectOfficeAddress, setSelectOfficeAddress] = useState<CoreOfficeAddress>();
  const [openOfficeUpload, setOfficeUpload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<Pagination<CoreOfficeAddress>>();
  //const [search, setSearch] = useState<string>();

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetCoreOfficeAddress({
      locationId,
      pageNumber: page,
      pageSize: perPage,
      sortBy: orderBy
    });
    if (code == 200) {
      setData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, locationId, orderBy]);

  async function onDeleteOffice(office: CoreOfficeAddress) {
    if (!office.id) return;
    const { data, code } = await callDeleteCoreOfficeAddress({
      locationId,
      officeAddressId: office.id
    });
    if (code == 200) {
      api();
    }
  }

  const rowData: TableRow[] = data
    ? generateRows(data.data, (type, office) => {
        if (type === 'edit') {
          setSelectOfficeAddress(office);
          setOfficeAddress(true);
        } else if (type === 'delete') {
          onDeleteOffice(office);
        }
      })
    : [];

  return (
    <Box>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          justifyContent: 'space-between'
        }}
      >
        <TextView display={'initial'} $fontSize={22}>
          Office
        </TextView>
        <Stack spacing={2} direction={'row'}>
          <Button variant="contained" onClick={() => setOfficeUpload(true)}>
            <Text>Upload Offices</Text>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setSelectOfficeAddress(undefined);
              setOfficeAddress(true);
            }}
          >
            <Text>Add Offices</Text>
          </Button>
        </Stack>
      </Box>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}

      <Table
        name="core_offices"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={data}
      />
      <UploadOfficesCSV
        locationId={locationId}
        open={openOfficeUpload}
        onClose={function (): void {
          setOfficeUpload(false);
        }}
        onSuccess={function (message?: string): void {
          setOfficeUpload(false);
          api();
        }}
      />
      <AddOffice
        locationId={locationId}
        open={openOfficeAddress}
        selectedOfficeAddress={selectOfficeAddress}
        onClose={function (): void {
          setOfficeAddress(false);
        }}
        onSuccess={function (): void {
          setOfficeAddress(false);
          api();
        }}
      />
    </Box>
  );
};

export default CoreOffices;
