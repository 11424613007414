import fetchAPI from '../fetchAPI';

interface callGetSrtTokenProps {
  from?: string;
  to?: string;
  companyTokenId?: string;
  locationId?: string;
  pageNumber?: number;
  pageSize?: number;
  markupType?: string;
}

export const callGetSrtTokens = async ({
  from,
  to,
  companyTokenId,
  pageNumber,
  pageSize,
  locationId,
  markupType
}: callGetSrtTokenProps): Promise<iResponse<Pagination<SrtToken>> | iResponse<SrtToken[]>> => {
  const queryParams = new URLSearchParams();
  if (from) queryParams.append('from', from);
  if (to) queryParams.append('to', to);
  if (companyTokenId) queryParams.append('companyTokenId', companyTokenId);
  if (locationId) queryParams.append('locationId', locationId);
  if (pageNumber) queryParams.append('pageNumber', pageNumber.toString());
  if (pageSize) queryParams.append('pageSize', pageSize.toString());
  if (markupType) queryParams.append('markupType', markupType);

  const queryString = queryParams.toString();

  const response: iResponse<Pagination<SrtToken>> = await fetchAPI({
    method: `GET`,
    route: `srt`,
    queryString
  });

  return response;
};

export default callGetSrtTokens;
