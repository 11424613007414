import fetchAPI from '../../fetchAPI';

interface callGetCoreUsersProps {
  locationId: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string | string[];
}

export const callGetCoreUsers = async (
  props: callGetCoreUsersProps
): Promise<iResponse<Pagination<CoreUser>>> => {
  const { pageNumber, pageSize, sortBy, locationId } = props;

  const queryParams = new URLSearchParams();
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (sortBy) {
    if (Array.isArray(sortBy)) {
      sortBy.forEach((sort) => queryParams.append('sortBy', sort));
    } else {
      queryParams.append('sortBy', sortBy);
    }
  }


  const queryString = queryParams.toString();
  const response: iResponse<Pagination<CoreUser>> = await fetchAPI({
    method: `GET`,
    route: `core/location/${locationId}/users`,
    queryString
  });

  return response;
};

export default callGetCoreUsers;
