import React, { useCallback, useState } from 'react';
import { Box, Modal, IconButton, Paper, Grid } from '@mui/material';
import { Close, FilePresent, CloudUploadOutlined } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

import { TextView, Button, Alert } from '@atoms';
import { callUploadInviteUsers, callUploadInviteUsersBySuperAdmin } from '@api';
import Doc from '@assets/example-salesrook-users.csv';

interface UploadUserProps {
  companyId?: string;
  open: boolean;
  onClose: () => void;
  onSuccess: (message?: string) => void;
}

const UploadUser: React.FC<UploadUserProps> = ({
  companyId,
  onClose,
  onSuccess,
  open
}: UploadUserProps) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>();

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const onDrop = useCallback((acceptedFiles: React.SetStateAction<File[] | undefined>) => {
    setError(undefined);
    // Do something with the files
    setAcceptedFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onSubmit = async () => {
    if (acceptedFiles && acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      if (companyId) {
        const { data, code, error } = await callUploadInviteUsersBySuperAdmin(companyId, file);
        if (code === 200) {
          // toast.success(data?.message?.data);
          onSuccess();
        } else {
          setError({ title: 'Error', message: error?.message ?? 'Something went wrong' });
        }
        return;
      }
      const { data, code, error } = await callUploadInviteUsers(file);
      code === 200
        ? onSuccess()
        : setError({ title: 'Error', message: error?.message ?? 'Something went wrong' });
    }
  };

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        setAcceptedFiles(undefined);
        setError(undefined);
        onClose();
      }}
    >
      <Grid md={3} sm={10} xs={10} item>
        <Paper sx={{ p: 4, width: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={30}>Upload users</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                setAcceptedFiles(undefined);
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              border: '1px dashed #242423',
              textAlign: 'center',
              borderRadius: '6px',
              paddingTop: '15%',
              paddingBottom: '15%',
              mt: 3
            }}
            {...getRootProps()}
          >
            {acceptedFiles == undefined ? (
              <div>
                <CloudUploadOutlined sx={{ fontSize: 40 }} />
                <input {...getInputProps()} />
                <TextView $fontSize={14} $fontWeight={400}>
                  Drop your file here
                </TextView>
              </div>
            ) : (
              <div>
                <FilePresent sx={{ fontSize: 40 }} />
                <TextView>File Selected</TextView>
              </div>
            )}
          </Box>
          <Box sx={{ mt: 1.5, mb: 2 }}>
            <TextView $fontSize={12} $fontWeight={400}>
              Only .CSV files are accepted as uploads here. {'\n'}
              <a href={Doc} download="UploadTemplate.csv" target="_blank" rel="noreferrer">
                Download
              </a>{' '}
              sample CSV template file for reference.
            </TextView>
          </Box>
          {error && <Alert severity={'error'} title={error.title} message={error.message} />}
          <Button sx={{ mt: 1 }} label={'Upload user'} onClick={onSubmit} />
        </Paper>
      </Grid>
    </Modal>
  );
};
export default UploadUser;
