import React, { useState, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { Avatar, Button, TextView } from '../../../atoms';
import StyledAccount from './account.style';
import { ApplicationContext } from '@particles';
import { ReactComponent as IconBuilding } from '@assets/svg/building.svg';
import { ReactComponent as IconWebsite } from '@assets/svg/website.svg';
import { ReactComponent as IconLocation } from '@assets/svg/location.svg';
import { EditAccountModal } from '@organisms';

import Image from '../../../assets/profile-background.png';

const Account: React.FC<RouteComponentProps> = () => {
  const {
    state: { user },
    dispatch
  } = useContext(ApplicationContext);
  const [openEdit, setEdit] = useState<boolean>(false);

  return (
    <StyledAccount>
      <TextView $fontSize={30} m={4}>
        Account
      </TextView>
      <Grid md={6} sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
        <img src={Image} alt={'background'} />
        <Avatar $size="xl" sx={{ mt: -7 }} />
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <TextView $fontSize={24}>{user?.name}</TextView>
          <TextView $fontWeight={400} $fontSize={14}>
            {user?.email}
          </TextView>
          <TextView $fontWeight={400} $fontSize={14}>
            {user?.phone}
          </TextView>
        </Box>
        <Box
          sx={{
            m: 4,
            p: 4,
            width: '50%',
            backgroundColor: '#E8EDDF',
            boxShadow:
              '0px 7px 140px rgba(141, 152, 121, 0.1), 0px 2px 32px rgba(141, 152, 121, 0.05), 0px 1px 10px rgba(141, 152, 121, 0.04)',
            borderRadius: '6px'
          }}
        >
          <Stack justifyContent={'space-between'}>
            <TextView $fontSize={24} alignSelf="center">
              Company Details
            </TextView>
          </Stack>

          <Divider sx={{ mb: 2, mt: 1 }} />
          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <IconBuilding />
            <Box sx={{ ml: 1, textAlign: 'initial' }}>
              <TextView $fontSize={24} $fontWeight={400}>
                {user?.company?.name}
              </TextView>
              <TextView $fontWeight={400}>{user?.company?.dbaName}</TextView>
            </Box>
          </Box>
          <Box sx={{ flexDirection: 'row', display: 'flex', p: 1, mt: 2 }}>
            <IconWebsite />
            <TextView sx={{ ml: 1 }} $fontWeight={400}>
              {user?.company?.website}
            </TextView>
          </Box>
          <Box sx={{ flexDirection: 'row', display: 'flex', p: 1 }}>
            <IconLocation />
            <TextView sx={{ ml: 1 }} $fontWeight={400}>
              {user?.company?.address}
            </TextView>
          </Box>
        </Box>
        <Button onClick={() => setEdit(true)}>Edit Account</Button>
      </Grid>

      <EditAccountModal
        open={openEdit}
        user={user}
        onClose={() => setEdit(false)}
        onSuccess={(user) => {
          setEdit(false);
          dispatch({ key: 'user', value: user, type: 'set' });
          localStorage.setItem('user', JSON.stringify(user));
        }}
      />
    </StyledAccount>
  );
};

export default Account;
