import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callGetAllTeams, callDeleteTeams } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, AlertPopUp } from '@atoms';

interface CompanyTeamProps {
  companyId: string;
  refresh?: number;
  isSuperAdmin?: boolean;
  onEdit: (team: Team) => void;
}

const CompanyTeam: React.FC<CompanyTeamProps> = (props: CompanyTeamProps) => {
  const { companyId, refresh, onEdit } = props;

  const {
    state: {
      teamPagination: { page, perPage }
    }
  } = useContext(TableContext);

  const [deleteTeam, setDeleteTeam] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [teams, setTeams] = useState<Pagination<Team>>();

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetAllTeams({
      companyId,
      pageNumber: page,
      pageSize: perPage
    });
    if (code == 200) {
      setTeams(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, refresh]);

  const onSwitch = async (team: Team, value: unknown, type: string) => {
    if (type == 'edit') {
      onEdit(team);
    } else if (type == 'delete') {
      setDeleteTeam(team.id);
    }
  };

  const apiDeleteTeam = async (teamId: string | null) => {
    if (teamId == null) {
      return;
    }
    setLoading(true);
    const { code, error } = await callDeleteTeams(teamId);
    if (code >= 400 && error) {
      setError(error.message);
      setTimeout(() => {
        setError(undefined);
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
      api();
    }
  };

  const rowData: TableRow[] = teams ? generateRows(teams.data, onSwitch) : [];

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}
      <Table name="team" columns={columns} data={rowData} isLoading={loading} pagination={teams} />
      <AlertPopUp
        open={deleteTeam != null}
        title={'Delete'}
        message={'Are you sure, you want to delete team?'}
        onSuccess={() => {
          apiDeleteTeam(deleteTeam);
          setDeleteTeam(null);
        }}
        onClose={() => {
          setDeleteTeam(null);
        }}
      />
    </Box>
  );
};

export default CompanyTeam;
