import * as React from 'react';
import { Box } from '@mui/material';

import { DateTime } from 'luxon';
import { TableContext } from '@particles';
import { TextView } from '@atoms';
import { callGetSrtTokens } from '@api';
import { Table } from '@organisms';

import { columns, generateRows } from './partials';

interface SrtTokenUsageListProps {
  dates: { start: DateTime; end: DateTime };
  locationId?: string;
  companyTokenId?: string;
}

const SrtTokenUsageList: React.FC<SrtTokenUsageListProps> = ({
  locationId,
  companyTokenId,
  dates
}: SrtTokenUsageListProps) => {
  const {
    state: {
      srtPagination: { page, perPage, orderBy }
    }
  } = React.useContext(TableContext);
  const [srtUsage, setSrtUsage] = React.useState<Pagination<SrtToken>>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const api = async () => {
      const startDate = dates.start.toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      const endDate = dates.end.toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });

      if (startDate && endDate && (locationId || companyTokenId)) {
        setLoading(true);
        const { code, data } = await callGetSrtTokens({
          from: startDate,
          to: endDate,
          pageNumber: page,
          pageSize: perPage,
          locationId,
          companyTokenId
        });

        if (code === 200 && data) {
          setSrtUsage(data as Pagination<SrtToken>);
        }
        setLoading(false);
      }
    };

    api();
  }, [page, perPage, dates, orderBy, locationId, companyTokenId]);

  const rowData: TableRow[] = srtUsage?.data ? generateRows(srtUsage.data) : [];

  return (
    <Box mt={6}>
      <TextView fontSize={22} fontWeight={600} sx={{ mb: 2 }}>
        Daily usage breakdown
      </TextView>
      <Table
        columns={columns}
        data={rowData}
        isLoading={loading}
        name={'srt'}
        pagination={srtUsage}
      />
    </Box>
  );
};

export default SrtTokenUsageList;
