import React, { useState, useEffect } from 'react';
import { Box, Modal, IconButton, Paper, Grid, LinearProgress, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import Validator from 'validator';
import { TextView, Button, TextField, Alert, Chip } from '@atoms';
import { Delete } from '@mui/icons-material';
import {
  callCreateCoreOfficeAddress,
  callUpdateCoreOfficeAddress,
  callDeleteCorePostalCode
} from '@particles';

interface AddCompanyTokenProps {
  locationId: string;
  open: boolean;
  selectedOfficeAddress?: CoreOfficeAddress;
  onClose: () => void;
  onSuccess: () => void;
}

const defaultCompanyToken: {
  officeId: string;
  location: string;
  readEmail: string;
  reapitEmail: string;
  robotEmail: string;
  mortgageEmail: string;
  postalCodes?: { id?: string; postalCode: string }[];
} = {
  officeId: '',
  location: '',
  readEmail: '',
  reapitEmail: '',
  robotEmail: '',
  mortgageEmail: '',
  postalCodes: []
};

const defaultErrorCompanyToken: {
  officeId: string;
  location: string;
  readEmail: string;
  reapitEmail: string;
  robotEmail: string;
  postalCodes: string;
  mortgageEmail: string;
} = {
  officeId: '',
  location: '',
  readEmail: '',
  reapitEmail: '',
  robotEmail: '',
  postalCodes: '',
  mortgageEmail: ''
};

const AddOffice: React.FC<AddCompanyTokenProps> = ({
  locationId,
  onClose,
  open,
  onSuccess,
  selectedOfficeAddress
}: AddCompanyTokenProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [postalCode, setPostalCode] = useState<string>('');
  const [values, setValues] = React.useState(defaultCompanyToken);

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const [errors, setErrors] = useState(defaultErrorCompanyToken);

  useEffect(() => {
    if (selectedOfficeAddress) {
      setValues({
        officeId: selectedOfficeAddress.officeId,
        location: selectedOfficeAddress.location,
        readEmail: selectedOfficeAddress.readEmail,
        reapitEmail: selectedOfficeAddress.reapitEmail,
        robotEmail: selectedOfficeAddress.robotEmail,
        mortgageEmail: selectedOfficeAddress.mortgageEmail,
        postalCodes: selectedOfficeAddress.postalCodes
      });
    }
  }, [selectedOfficeAddress]);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prop == 'postal_code') {
      setPostalCode(event.target.value);
      return;
    }

    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { officeId, location, readEmail, reapitEmail, robotEmail, postalCodes, mortgageEmail } =
      values;

    if (validateFields()) {
      return;
    }

    const officeAddress: CoreOfficeAddress = {
      locationId,
      officeId,
      location,
      readEmail,
      reapitEmail,
      robotEmail,
      mortgageEmail,
      postalCodes
    };

    if (selectedOfficeAddress && selectedOfficeAddress.id) {
      setLoading(true);
      callUpdateCoreOfficeAddress({ ...officeAddress, id: selectedOfficeAddress.id })
        .then((res) => {
          if (res.code == 200) {
            setValues(defaultCompanyToken);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      callCreateCoreOfficeAddress(officeAddress)
        .then((res) => {
          if (res.code == 201) {
            setValues(defaultCompanyToken);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
  };

  const validateFields = () => {
    let error = false;
    setErrors(defaultErrorCompanyToken);
    if (Validator.isEmpty(values.officeId.trim())) {
      error = true;
      setErrors((state) => ({ ...state, officeId: 'OfficeID is required' }));
    }

    if (Validator.isEmpty(values.location.trim())) {
      error = true;
      setErrors((state) => ({ ...state, location: 'Location is required' }));
    }

    if (Validator.isEmpty(values.readEmail.trim())) {
      error = true;
      setErrors((state) => ({ ...state, readEmail: 'Read Email is required' }));
    }

    if (Validator.isEmpty(values.reapitEmail.trim())) {
      error = true;
      setErrors((state) => ({ ...state, reapitEmail: 'Reapit Email is required' }));
    }

    if (Validator.isEmpty(values.robotEmail.trim())) {
      error = true;
      setErrors((state) => ({ ...state, robotEmail: 'Robot Email is required' }));
    }

    if (Validator.isEmpty(values.mortgageEmail.trim())) {
      error = true;
      setErrors((state) => ({ ...state, mortgageEmail: 'Mortgage Email is required' }));
    }

    if (values.postalCodes?.length == 0) {
      error = true;
      setErrors((state) => ({ ...state, postal_code: 'PostalCodes is required' }));
    }

    return error;
  };

  function validateOutwardAndInwardCode(postcode: string): boolean {
    // Define regex for outward code and the first digit of the inward code
    const pattern = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9]/i;

    // Test the postcode against the pattern
    return pattern.test(postcode.trim());
  }

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        setValues(defaultCompanyToken);
        setErrors(defaultErrorCompanyToken);
        onClose();
      }}>
      <Grid md={6} sm={10} xs={10} container>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={26}>{`${
              selectedOfficeAddress ? 'Edit' : 'Add'
            } Office Address`}</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                onClose();
                setValues(defaultCompanyToken);
                setErrors(defaultErrorCompanyToken);
              }}>
              <Close />
            </IconButton>
          </Box>
          {loading && <LinearProgress />}
          <form onSubmit={onSubmit}>
            <Grid spacing={2} container>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Reapit Office Id'}
                  name={'officeId'}
                  errorMessage={errors.officeId}
                  fullWidth
                  placeholder="MKT"
                  inputType="text"
                  value={values.officeId}
                  onChange={handleChange('officeId')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Office Location'}
                  name={'location'}
                  placeholder="Birmingham"
                  errorMessage={errors.location}
                  fullWidth
                  inputType="text"
                  value={values.location}
                  onChange={handleChange('location')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Read Email'}
                  name={'readEmail'}
                  errorMessage={errors.readEmail}
                  fullWidth
                  inputType="email"
                  value={values.readEmail}
                  onChange={handleChange('readEmail')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Reapit Email'}
                  name={'reapitEmail'}
                  errorMessage={errors.reapitEmail}
                  fullWidth
                  inputType="email"
                  value={values.reapitEmail}
                  onChange={handleChange('reapitEmail')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Robot Email'}
                  name={'robotEmail'}
                  errorMessage={errors.robotEmail}
                  fullWidth
                  inputType="email"
                  value={values.robotEmail}
                  onChange={handleChange('robotEmail')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Mortgage Email'}
                  name={'mortgageEmail'}
                  errorMessage={errors.mortgageEmail}
                  fullWidth
                  inputType="email"
                  value={values.mortgageEmail}
                  onChange={handleChange('mortgageEmail')}
                />
              </Grid>
            </Grid>

            <TextView $fontSize={20} mt={3}>
              Postal Code
            </TextView>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2} direction="row" alignItems={'end'}>
                <TextField
                  label={''}
                  name={'postal_code'}
                  fullWidth
                  inputType="text"
                  value={postalCode}
                  errorMessage={errors.postalCodes}
                  onChange={handleChange('postal_code')}
                />
                <Button
                  label={'Add'}
                  size="small"
                  sx={{ bottom: errors.postalCodes ? 20 : 5 }}
                  onClick={() => {
                    if (postalCode.trim().length == 0) {
                      setErrors((state) => ({ ...state, postalCodes: 'PostalCode is required' }));
                      return;
                    }

                    if (validateOutwardAndInwardCode(postalCode)) {
                      setValues({
                        ...values,
                        postalCodes: [...(values.postalCodes || []), { postalCode }]
                      });
                      setPostalCode('');
                    } else {
                      setErrors((state) => ({ ...state, postal_code: 'PostalCode Invalid' }));
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid direction={'row'} spacing={1} container mt={1}>
              {values.postalCodes?.map((item, index) => (
                <Grid item key={index}>
                  <Chip
                    onDelete={async () => {
                      if (selectedOfficeAddress && selectedOfficeAddress.id && item.id) {
                        await callDeleteCorePostalCode({
                          locationId,
                          officeAddressId: selectedOfficeAddress.id,
                          postalCodeId: item.id
                        });
                      }

                      values.postalCodes?.splice(index, 1);
                      setValues({ ...values, postalCodes: [...(values.postalCodes || [])] });
                    }}
                    label={item.postalCode}
                    variant="filled"
                    deleteIcon={<Delete />}
                    $backgroundColor="#f2f2f2"
                  />
                </Grid>
              ))}
            </Grid>
            {error && <Alert severity={'error'} title={error.title} message={error.message} />}

            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                mt: 4
              }}>
              <Button
                label={`${selectedOfficeAddress ? 'Update' : 'Add'}`}
                size="small"
                type="submit"
              />
            </Box>
          </form>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AddOffice;
