import fetchAPI from '../fetchAPI';

// Define a function callUserConnectGhl that makes an asynchronous API call
export const callUserConnectGhl = async (): Promise<iResponse<{ active: boolean }>> => {
  try {
    // Make an API call using fetchAPI
    const response: iResponse<{ active: boolean }> = await fetchAPI({
      method: 'GET',
      route: `core/integration`,
      queryString: ''
    });

    // Return the response
    return response;
  } catch (error) {
    // Handle errors if the API call fails
    console.error('Error fetching GhlConnect:', error);
    throw error; // Throw the error to be handled by the caller
  }
};

export default callUserConnectGhl;
