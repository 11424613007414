import { routes } from '@particles';
import { DateTime } from 'luxon';

const generateRows = (users: CoreUser[]): TableRow[] =>
  users.map((user) => {
    const payload: TableRow = {
      name: user.name ?? '',
      email: user.email ?? '',
      phone: user.phone ?? '',
      type: user.type ?? '',
      role: user.role ?? '',
      updatedAt: user.updatedAt
        ? DateTime.fromISO(user.updatedAt).toLocaleString(DateTime.DATETIME_MED)
        : ''
      // linkData: {
      //   to: `${routes.SA_Core}/${token.locationId}`
      // }
    };
    return payload;
  });

export default generateRows;
