import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, TextView } from '@atoms';
import { RouteComponentProps, useLocation } from '@reach/router';
import APIList from './apis';
import { Divider, Grid, Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { callDisconnectGhl, callUserConnectGhl, callValidateGhlCode } from '@particles';
import { parse } from 'qs';
import MyProfile from './my-profile';

const Settings: React.FC<RouteComponentProps> = () => {
  const [connectGhl, setConnectGhl] = useState<boolean>();
  const location = useLocation();
  React.useEffect(() => {
    const searchParams = parse(location.search);
    const code = searchParams.code;
    if (code) {
      callValidateGhlCode(code as string);
    }
  }, [location]);

  React.useEffect(() => {
    callUserConnectGhl().then((res) => {
      if (res && res.code === 200 && res.data) {
        setConnectGhl(res.data.active);
      }
    });
  }, []);

  const [integrationInProgress, setIntegrationInProgress] = useState<boolean>(false);

  const onConnect = () => {
    setIntegrationInProgress(true);

    const left = (screen.width - 600) / 2;
    const top = (screen.height - 400) / 2;
    const options =
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
      600 +
      ', height=' +
      400 +
      ', top=' +
      top +
      ', left=' +
      left;
    const popup = window.open('/oauth/core', 'Ghl Integration', options);

    if (!popup || popup.closed || typeof popup.closed == 'undefined') {
      alert('Popup blocked! Please enable popups for this site.');
    }

    // Check if the window was successfully opened
    if (popup) {
      // Listen for window close event
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
          // Window is closed, integration is complete
          setIntegrationInProgress(false);
        }
      }, 1000); // Check every second if the window is closed
    } else {
      console.error('Failed to open login window');
      // Handle error, if needed
      setIntegrationInProgress(false);
    }
  };

  const onDisconnect = async () => {
    const { code } = await callDisconnectGhl();
    if (code == 200) {
      callUserConnectGhl().then((res) => {
        if (res && res.code === 200 && res.data) {
          setConnectGhl(res.data.active);
        }
      });
    }
  };

  return (
    <Box p={3}>
      <TextView display={'initial'} $fontSize={30}>
        Settings
      </TextView>
      <Divider />

      <Grid container xs={12} md={12} spacing={4} mt={4}>
        <Grid item xs={12} md={6}>
          <MyProfile />
        </Grid>
        <Grid item xs={12} md={6}>
          <APIList />
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper>
            <Stack direction={'row'} mt={3} p={2} justifyContent={'space-between'}>
              <TextView display={'initial'} $fontSize={22}>
                Connect with Go High Level (GHL)
              </TextView>

              {connectGhl == true ? (
                <Button
                  sx={{ ml: 2, backgroundColor: 'red', color: 'white' }}
                  size="small"
                  variant="outlined"
                  onClick={onDisconnect}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  sx={{
                    ml: 2
                  }}
                  variant="contained"
                  size="small"
                  onClick={onConnect}
                >
                  Connect
                </Button>
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
