import fetchAPI from '../fetchAPI';

interface callGetUnifiedUserProps {
  sortBy?: string[] | string;
  page?: number;
  perPage?: number;
  name?: string;
  email?: string;
  phone?: string;
  type?: 'core' | 'negotiator' | 'all';
}

export const callGetUnifiedUser = async (
  props: callGetUnifiedUserProps
): Promise<iResponse<Pagination<UnifedUser>>> => {
  const { name, email, phone, page, perPage, sortBy, type } = props;

  const query = new URLSearchParams();
  if (page) query.append('pageNumber', page.toString());
  if (perPage) query.append('pageSize', perPage.toString());
  if (name) query.append('name', name);
  if (email) query.append('email', email);
  if (phone) query.append('phone', phone);
  if (type) query.append('type', type);
  if (sortBy) {
    if (Array.isArray(sortBy)) {
      sortBy.forEach((sortByItem) => query.append('sortBy', sortByItem));
    } else {
      query.append('sortBy', sortBy);
    }
  }

  const response: iResponse<Pagination<UnifedUser>> = await fetchAPI({
    method: `GET`,
    route: `user/unified`,
    queryString: query.toString(),
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetUnifiedUser;
