import fetchAPI from '../fetchAPI';

interface callGetAllTeamProps {
  companyId?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const callGetAllTeams = async (
  props: callGetAllTeamProps
): Promise<iResponse<Pagination<Team>>> => {
  const { companyId, pageNumber, pageSize } = props;

  const queryParams = new URLSearchParams();
  if (companyId) {
    queryParams.append('companyId', companyId);
  }
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  const queryString = queryParams.toString();
  const response: iResponse<Pagination<Team>> = await fetchAPI({
    method: `GET`,
    route: `team`,
    queryString
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetAllTeams;
