import fetchAPI from '../../fetchAPI';

export const callDeleteCorePostalCode = async ({
  locationId,
  officeAddressId,
  postalCodeId
}: {
  locationId: string;
  officeAddressId: string;
  postalCodeId: string;
}): Promise<iResponse<Pagination<CoreOfficeAddress>>> => {
  const response: iResponse<Pagination<CoreOfficeAddress>> = await fetchAPI({
    method: `DELETE`,
    route: `core/location/${locationId}/offices-address/${officeAddressId}/postal-code/${postalCodeId}`
  });

  return response;
};

export default callDeleteCorePostalCode;
