import fetchAPI from '../../fetchAPI';

export const callGetSyncCoreUsers = async (locationId: string): Promise<iResponse<any>> => {
  const response: iResponse<any> = await fetchAPI({
    method: `GET`,
    route: `core/location/${locationId}/sync-users`
  });

  return response;
};

export default callGetSyncCoreUsers;
