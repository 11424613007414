import React, { ReactNode, useState } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch
} from '@mui/material';
import { CellProps } from 'react-table';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Chip, Text } from '../../../atoms';
import { materialTheme, isObject } from '../../../particles';

export type TableCellProps = CellProps<TableRow, TableCell>;

const renderCellType = (columnId: string, cellTypeData: TableCellType) => {
  const { type } = cellTypeData;

  switch (type) {
    case `chip`: {
      const chipData = cellTypeData as TableCellChip;
      const { data } = chipData;

      return (
        <Grid direction={'row'} rowSpacing={1}>
          {data.map((item, index) => {
            const { text } = item;
            const { backgroundColor } = item;
            const textColor =
              backgroundColor && materialTheme.palette.getContrastText(backgroundColor);
            return (
              <Chip
                key={index}
                label={text}
                sx={{ ml: index > 0 ? 1 : 0 }}
                {...(backgroundColor && { $backgroundColor: backgroundColor })}
                {...(textColor && { $textColor: textColor })}
              />
            );
          })}
        </Grid>
      );
    }

    case `text`: {
      const textData = cellTypeData as TableCellText;
      const { data } = textData;

      return (
        <React.Fragment>
          {data && (
            <Text $fontWeight={400} $fontSize={16} $fontColor={data.fontColor}>
              {data.text}
            </Text>
          )}
        </React.Fragment>
      );
    }
    case `action`: {
      const textData = cellTypeData as TableCellAction;
      const { data } = textData;

      return (
        <Stack direction={'row'}>
          {data.map((item, index) => (
            <IconButton
              key={`action_button_${index}`}
              aria-label="delete"
              sx={{ mr: 2, width: 40 }}
              onClick={(event) => {
                event.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                item.linkData.onClick(event);
              }}
            >
              {item.type == 'delete' && <DeleteIcon color={'error'} />}
              {item.type == 'edit' && <EditIcon />}
              {item.type == 'other' && item.icon}
            </IconButton>
          ))}
        </Stack>
      );
    }
    case `switch`: {
      const textData = cellTypeData as TableCellSwitch;
      const { data } = textData;

      const [checked, setChecked] = useState<boolean>(data.selected);

      return (
        <React.Fragment>
          <Stack direction={'row'} alignItems={'center'}>
            <Switch
              color="secondary"
              checked={checked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                setChecked(event.target.checked);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                data.linkData?.onClick(event.target.checked);
              }}
            />
            <Text $fontWeight={400} $fontSize={12}>
              {checked ? data.enable : data.disable}
            </Text>
          </Stack>
        </React.Fragment>
      );
    }
    case `selector`: {
      const textData = cellTypeData as TableCellSelector;
      const { data } = textData;
      const [selected, setSelected] = useState<string | null>(data.selected);
      return (
        <React.Fragment>
          <FormControl fullWidth size="small">
            <Select
              value={selected != null ? selected : '0'}
              onChange={(event: SelectChangeEvent) => {
                const value = event.target.value;
                setSelected(value);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                data.linkData?.onClick(value == 0 ? null : value);
              }}
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={'applicant'}>Applicant</MenuItem>
              <MenuItem value={'contact'}>Contact</MenuItem>
              <MenuItem value={'landlord'}>Landlord</MenuItem>
              <MenuItem value={'tenancy'}>Tenancy</MenuItem>
              <MenuItem value={'vendor'}>Vendor</MenuItem>
            </Select>
          </FormControl>
        </React.Fragment>
      );
    }
  }
};

export const TableCell: React.FC<TableCellProps> = (props: TableCellProps): React.ReactElement => {
  const {
    value: cellData,
    cell: {
      column: { id }
    }
  } = props;

  if (isObject(cellData)) {
    return <React.Fragment>{renderCellType(id, cellData as TableCellType)}</React.Fragment>;
  } else {
    return <React.Fragment>{cellData?.toString()}</React.Fragment>;
    //return <React.Fragment>{renderCellType(id, cellData as TableCellType)}</React.Fragment>;
  }
};

export default TableCell;
