import React from 'react';
import { Box, Modal, IconButton, Paper, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TextView } from '../../../../atoms';
import { DateTime } from 'luxon';

interface LicenceDetailsProps {
  open: boolean;
  selectedLicenseHistory?: LicenseHistory;
  onClose: () => void;
}

const LicenceDetailsModel: React.FC<LicenceDetailsProps> = ({
  onClose,
  open,
  selectedLicenseHistory
}: LicenceDetailsProps) => {
  const paymentDate = selectedLicenseHistory?.paymentDate;
  const startDate = selectedLicenseHistory?.startDate;
  const endDate = selectedLicenseHistory?.endDate;
  return (
    <Modal
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Paper sx={{ p: 4, width: '30%' }}>
        <Box sx={{ flexDirection: 'row', display: 'flex', mb: 2 }}>
          <TextView $fontSize={30}>Billing history</TextView>
          <Box sx={{ flex: 1 }} />
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <TextView $fontSize={24}>Current licence details</TextView>

        <Stack direction={'row'} mt={3}>
          <Box sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', borderRadius: 1, p: 1, flex: 1 }}>
            <TextView $fontSize={16} $fontWeight={400}>
              Licensed users
            </TextView>
            <TextView $fontSize={18} $fontWeight={600}>
              {selectedLicenseHistory?.users}
            </TextView>
          </Box>
          <Box
            sx={{
              backgroundColor: 'rgba(36, 36, 35, 0.08)',
              borderRadius: 1,
              p: 1,
              flex: 1,
              ml: 4
            }}
          >
            <TextView $fontSize={16} $fontWeight={400}>
              Active users
            </TextView>
            <TextView $fontSize={18} $fontWeight={600}>
              {'--'}
            </TextView>
          </Box>
        </Stack>
        <Stack direction={'row'} mt={3}>
          <Box sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', borderRadius: 1, p: 1, flex: 1 }}>
            <TextView $fontSize={16} $fontWeight={400}>
              Payment Date
            </TextView>
            <TextView $fontSize={18} $fontWeight={600}>
              {paymentDate ? DateTime.fromISO(paymentDate).toFormat('DD') : ''}
            </TextView>
          </Box>
          <Box
            sx={{
              backgroundColor: 'rgba(36, 36, 35, 0.08)',
              borderRadius: 1,
              p: 1,
              flex: 1,
              ml: 4
            }}
          >
            <TextView $fontSize={16} $fontWeight={400}>
              Amount Paid
            </TextView>
            <TextView $fontSize={18} $fontWeight={600}>
              {`£${selectedLicenseHistory?.amount}`}
            </TextView>
          </Box>
        </Stack>
        <Stack direction={'row'} mt={3}>
          <Box sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', borderRadius: 1, p: 1, flex: 1 }}>
            <TextView $fontSize={16} $fontWeight={400}>
              Licence Start Date
            </TextView>
            <TextView $fontSize={18} $fontWeight={600}>
              {startDate ? DateTime.fromISO(startDate).toFormat('DD') : ''}
            </TextView>
          </Box>
          <Box
            sx={{
              backgroundColor: 'rgba(36, 36, 35, 0.08)',
              borderRadius: 1,
              p: 1,
              flex: 1,
              ml: 4
            }}
          >
            <TextView $fontSize={16} $fontWeight={400}>
              Licence End Date
            </TextView>
            <TextView $fontSize={18} $fontWeight={600}>
              {endDate ? DateTime.fromISO(endDate).toFormat('DD') : ''}
            </TextView>
          </Box>
        </Stack>
        <Box
          sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', borderRadius: 1, p: 1, flex: 1, mt: 3 }}
        >
          <TextView $fontSize={16} $fontWeight={400}>
            Remark
          </TextView>
          <TextView $fontSize={18} $fontWeight={600}>
            {selectedLicenseHistory?.remarks}
          </TextView>
        </Box>
      </Paper>
    </Modal>
  );
};
export default LicenceDetailsModel;
