const generateRows = (
  offices: CoreOfficeAddress[],
  onAction: (type: 'edit' | 'delete', office: CoreOfficeAddress) => void
): TableRow[] =>
  offices.map((office) => {
    const action: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'edit',
          linkData: {
            onClick: () => {
              onAction('edit', office);
            }
          }
        },
        {
          type: 'delete',
          linkData: {
            onClick: () => {
              onAction('delete', office);
            }
          }
        }
      ]
    };
    const postalCodes: TableCellChip = {
      type: 'chip',
      data:
        office.postalCodes?.map((item) => {
          return {
            text: item.postalCode,
            backgroundColor: '#d7d7d7'
          };
        }) || []
    };
    const payload: TableRow = {
      officeId: office.officeId ?? '',
      location: office.location ?? '',
      readEmail: office.readEmail ?? '',
      reapitEmail: office.reapitEmail ?? '',
      robotEmail: office.robotEmail ?? '',
      mortgageEmail: office.mortgageEmail ?? ' - ',
      postalCodes,
      action
      // linkData: {
      //   to: `${routes.SA_Core}/${token.locationId}`
      // }
    };
    return payload;
  });

export default generateRows;
