import React from 'react';
import { Box, LinearProgress, Stack } from '@mui/material';
import { TextView } from '../../../../../atoms';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface TotalMessageChartProps {
  loading: boolean;
  companyEngagement: Engagement[];
}

const TotalMessageChart: React.FC<TotalMessageChartProps> = ({
  companyEngagement,
  loading
}: TotalMessageChartProps) => {
  interface CustomizedAxisTickProps {
    x: number;
    y: number;
    payload: { value: any };
  }

  const CustomizedAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ fontFamily: 'Montserrat', fontSize: 8, fontWeight: 400 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          textAnchor="end"
          fill="#666"
          style={{ fontFamily: 'Montserrat', fontSize: 10, fontWeight: 400 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', p: 1, borderRadius: 1, height: 350 }}>
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <TextView $fontSize={24} sx={{ marginLeft: '20px', color: 'black' }}>
        Total Messages
      </TextView>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 2 }}>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#086BDE', mr: 1 }} />
        <TextView $fontSize={12} $fontWeight={400}>
          Received
        </TextView>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#F5CB5C', ml: 1, mr: 1 }} />
        <TextView $fontSize={12} $fontWeight={400}>
          Sent
        </TextView>
      </Stack>
      <ResponsiveContainer width="97%" height="80%">
        <BarChart data={companyEngagement}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            height={80}
            tick={(props) => CustomizedAxisTick(props)}
          />
          <YAxis tick={(props) => CustomizedYAxisTick(props)} />
          <Tooltip
            itemSorter={(item) => {
              switch (item.dataKey) {
                case 'sent':
                  return 0;
                case 'received':
                  return 1;
                default:
                  return 2;
              }
            }}
          />
          <Bar dataKey="received" stackId="a" fill="#086BDE" />
          <Bar dataKey="sent" stackId="a" fill="#F5CB5C" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TotalMessageChart;
