import React, { useState, useContext, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Box, Grid, Paper, LinearProgress } from '@mui/material';
import { Alert, Button, TextField, TextView } from '../../../atoms';
import StyledLogin from './login.style';
import { app, auth } from '../../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ApplicationContext } from '@particles';
import { callGetUser } from '@api';
import { ReactComponent as IconSalesrook } from '@assets/svg/salesrook.svg';
import Validator from 'validator';

let navigating = false;
const Login: React.FC<RouteComponentProps> = () => {
  const {
    state: { user },
    dispatch
  } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user && !navigating) {
      navigating = true;
      navigate(`/`);
      setLoading(false);
    }
  }, [user, navigating]);

  useEffect(() => {
    app.name;
  }, []);

  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState<string>();

  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const validateFields = () => {
    let error = false;

    setErrors({
      email: '',
      password: ''
    });

    if (!Validator.isEmail(values.email)) {
      error = true;
      setErrors((state) => ({ ...state, email: 'Enter a valid email address' }));
    }

    if (values.password.length < 6) {
      error = true;
      setErrors((state) => ({ ...state, password: 'Enter a minimum of 6 characters' }));
    }

    return error;
  };

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onLogin = () => {
    // alert('hello')
    if (validateFields()) {
      return;
    }

    setLoading(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;

        const token = await user.getIdToken();
        localStorage.setItem('token', token);

        callGetUser({ email: user.email ?? '' })
          .then((value) => {
            dispatch({ key: 'user', value: value.data, type: 'set' });
            localStorage.setItem('user', JSON.stringify(value.data));
            navigating = false;
          })
          .catch(() => {
            setLoading(false);
            localStorage.clear();
          });

        // ...
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.message as string;
        if (errorMessage.includes('auth/wrong-password')) {
          setError('Password provided is incorrect!');
        } else if (errorMessage.includes('auth/user-not-found')) {
          setError('No user found for this email address!');
        } else {
          setError(errorMessage);
        }
        setTimeout(() => setError(undefined), 3000);
      });
  };

  return (
    <StyledLogin>
      <svg
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          objectFit: 'cover'
        }}
        viewBox="0 0 1241 845"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6" clipPath="url(#clip0_0_1)">
          <path
            d="M107.22 29.8264L107.503 30.9925L103.416 31.9854C103.145 33.3137 103.799 34.7064 105.08 35.3036C106.454 35.9444 108.071 35.4511 108.869 34.2101L112.686 34.33C113.665 36.4087 116.093 37.4389 118.294 36.6374C120.626 35.7888 121.832 33.2015 120.983 30.87L121.547 30.6648C122.013 31.9446 121.953 33.33 121.377 34.5644C121.04 35.2871 120.547 35.9037 119.942 36.3876C119.735 36.5532 119.514 36.7012 119.283 36.8343C119.261 36.8467 119.239 36.8575 119.217 36.8699C118.988 36.9969 118.75 37.1103 118.499 37.2017C118.279 37.2818 118.056 37.3415 117.834 37.3892C117.782 37.4003 117.73 37.4079 117.679 37.4173C117.497 37.4502 117.315 37.4745 117.133 37.4884C116.1 37.5644 115.079 37.3271 114.204 36.8222C112.115 40.0134 107.951 41.2769 104.392 39.6172L99.9013 52.0876L115.127 59.1876C117.652 53.7732 121.301 49.7775 124.685 46.5495C121.095 47.6786 117.091 47.5247 113.42 45.8129L113.673 45.2691C121.169 48.7646 130.112 45.5098 133.607 38.0137C137.103 30.5177 133.848 21.5753 126.352 18.0798L125.808 17.8263L123.272 23.2641L122.729 23.0105L123.743 20.8354L119.936 19.0604C117.834 18.0801 115.335 18.9896 114.355 21.0919C113.891 22.0876 103.687 21.2545 101.597 25.7353C100.74 27.5733 101.328 29.7136 102.893 30.8779L107.22 29.8264ZM119.983 22.4872L120.108 23.6807L116.629 24.0442L116.504 22.8507L119.983 22.4872ZM103.428 26.7002L105.566 26.3734L105.747 27.5595L103.609 27.8863L103.428 26.7002ZM117.593 60.9993C118.191 61.2782 118.452 61.9958 118.173 62.594C117.894 63.1921 117.176 63.4533 116.578 63.1744L95.9145 53.5387C95.3163 53.2598 95.0551 52.5422 95.3341 51.944C95.613 51.3458 96.3306 51.0846 96.9288 51.3636L117.593 60.9993ZM119.117 67.6683C118.559 68.8646 117.124 69.387 115.928 68.8292L92.001 57.6721C90.8047 57.1142 90.2823 55.679 90.8401 54.4826C91.398 53.2863 92.8332 52.7639 94.0296 53.3218L117.956 64.4789C119.152 65.0368 119.675 66.472 119.117 67.6683Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M255.849 187.611C256.221 188.409 255.873 189.366 255.075 189.738L239.124 197.176C238.327 197.548 237.37 197.199 236.998 196.402C236.626 195.604 236.974 194.647 237.772 194.276L253.723 186.837C254.521 186.466 255.477 186.814 255.849 187.611ZM251.052 232.218L274.253 221.399C275.051 221.027 275.399 220.071 275.027 219.273C274.655 218.475 273.698 218.127 272.901 218.499L249.699 229.318C248.902 229.69 248.553 230.647 248.925 231.444C249.297 232.242 250.254 232.59 251.052 232.218ZM276.766 221.11L249.214 233.958C247.619 234.701 246.923 236.615 247.667 238.21C248.41 239.805 250.324 240.502 251.919 239.758L279.471 226.91C281.066 226.167 281.763 224.253 281.019 222.658C280.275 221.063 278.361 220.366 276.766 221.11ZM240.893 197.234C250.815 219.036 250.312 222.424 251.819 227.447L270.105 218.92C267.226 214.537 264.307 212.745 253.983 191.13L240.893 197.234ZM231.127 187.664L237.329 193.6L253.491 186.063L252.93 177.497L231.127 187.664ZM252.874 176.641L252.521 171.267L249.327 171.476L249.524 174.475C247.882 174.458 244.912 175.263 241.533 176.679C241.92 175.851 241.964 174.871 241.548 173.978C240.801 172.377 238.897 171.684 237.295 172.43C235.694 173.177 235.001 175.082 235.747 176.683C236.164 177.575 236.943 178.172 237.825 178.408C234.568 180.087 232.042 181.844 231 183.113L228.829 181.034L226.616 183.346L230.507 187.071L252.874 176.641Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6" clipPath="url(#clip1_0_1)">
          <path
            d="M79.0018 337.517C79.3907 337.128 80.0271 337.128 80.416 337.517L88.1942 345.295C88.5831 345.684 88.5831 346.32 88.1942 346.709C87.8053 347.098 87.1689 347.098 86.78 346.709L79.0018 338.931C78.6129 338.542 78.6129 337.906 79.0018 337.517ZM61.6777 352.719L72.9914 364.033C73.3803 364.422 74.0167 364.422 74.4056 364.033C74.7945 363.644 74.7945 363.008 74.4056 362.619L63.0919 351.305C62.703 350.916 62.0666 350.916 61.6777 351.305C61.2888 351.694 61.2888 352.33 61.6777 352.719ZM73.6985 365.447L60.2635 352.012C59.4856 351.234 58.2128 351.234 57.435 352.012C56.6572 352.79 56.6572 354.063 57.435 354.841L70.8701 368.276C71.6479 369.054 72.9207 369.054 73.6985 368.276C74.4763 367.498 74.4763 366.225 73.6985 365.447ZM84.6586 341.052C87.1972 343.591 91.3125 343.591 93.851 341.052C96.3895 338.514 96.3895 334.398 93.851 331.86C91.3125 329.321 87.1972 329.321 84.6586 331.86C82.1201 334.398 82.1201 338.514 84.6586 341.052ZM85.3657 346.002L79.7089 340.345C67.8726 352.181 66.27 350.919 64.1561 351.662L74.0485 361.555C74.792 359.441 73.5295 357.838 85.3657 346.002Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M164.197 547.091L160.393 538.934L164.2 537.159L165.721 540.422L168.984 538.9L167.462 535.638L173.988 532.595L175.509 535.858L178.772 534.336L177.25 531.073L181.057 529.298L184.86 537.455C185.418 538.651 184.896 540.087 183.7 540.645L167.386 548.252C166.19 548.81 164.754 548.287 164.197 547.091ZM184.967 543.363C185.566 543.085 185.827 542.367 185.548 541.769C185.269 541.171 184.551 540.909 183.953 541.188L167.64 548.795C167.041 549.074 166.78 549.792 167.059 550.39C167.338 550.988 168.056 551.25 168.654 550.971L184.967 543.363ZM178.613 579.428L200.365 569.285C200.963 569.006 201.224 568.288 200.945 567.69C200.666 567.092 199.949 566.831 199.351 567.11L177.599 577.253C177.001 577.532 176.74 578.249 177.019 578.847C177.298 579.445 178.015 579.707 178.613 579.428ZM202.25 569.068L177.236 580.732C176.039 581.29 175.517 582.725 176.075 583.922C176.633 585.118 178.068 585.64 179.264 585.082L204.278 573.418C205.475 572.86 205.997 571.425 205.439 570.229C204.881 569.032 203.446 568.51 202.25 569.068ZM198.152 567.007C195.59 564.064 192.5 563.776 183.59 544.668L170.539 550.754C179.449 569.862 177.684 572.414 178.291 576.268L198.152 567.007Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6" clipPath="url(#clip2_0_1)">
          <path
            d="M26.3848 766.802C26.0312 766.381 26.0867 765.747 26.508 765.394L34.9345 758.323C35.3558 757.969 35.9898 758.025 36.3434 758.446C36.6969 758.867 36.6414 759.501 36.2201 759.855L27.7936 766.926C27.3723 767.279 26.7383 767.224 26.3848 766.802ZM40.0198 785.386L52.2765 775.101C52.6979 774.747 52.7533 774.113 52.3998 773.692C52.0463 773.271 51.4123 773.215 50.991 773.569L38.7342 783.854C38.3129 784.207 38.2575 784.841 38.611 785.262C38.9645 785.684 39.5985 785.739 40.0198 785.386ZM53.747 774.52L39.1921 786.733C38.3495 787.44 38.2386 788.708 38.9456 789.55C39.6527 790.393 40.9206 790.504 41.7633 789.797L56.3181 777.584C57.1608 776.877 57.2717 775.609 56.5647 774.766C55.8576 773.924 54.5896 773.813 53.747 774.52ZM30.3999 761.475C33.15 759.168 33.5087 755.068 31.2011 752.318C28.8934 749.568 24.7937 749.209 22.0436 751.517C19.2935 753.824 18.9349 757.924 21.2425 760.674C23.5501 763.424 27.6498 763.783 30.3999 761.475ZM35.3924 761.202L29.2641 766.344C40.0237 779.167 38.6263 780.654 39.1827 782.824L49.8997 773.832C47.8586 772.907 46.152 774.025 35.3924 761.202Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M279.888 789.749C280.087 789.005 280.859 788.56 281.603 788.759L296.478 792.745C297.222 792.944 297.667 793.716 297.468 794.46C297.268 795.203 296.497 795.649 295.753 795.45L280.878 791.464C280.134 791.264 279.689 790.493 279.888 789.749ZM269.888 818.956L291.525 824.754C292.268 824.953 293.04 824.507 293.239 823.764C293.438 823.02 292.993 822.248 292.249 822.049L270.613 816.252C269.869 816.052 269.097 816.498 268.898 817.241C268.699 817.985 269.144 818.757 269.888 818.956ZM293.372 825.973L267.678 819.089C266.191 818.69 264.648 819.581 264.249 821.069C263.85 822.556 264.741 824.099 266.229 824.498L291.922 831.383C293.41 831.781 294.953 830.89 295.352 829.403C295.75 827.915 294.859 826.372 293.372 825.973ZM282.015 792.493C276.775 811.081 274.784 812.486 273.107 816.195L290.117 820.753C290.519 816.703 289.497 814.49 294.253 795.772L282.015 792.493ZM295.307 791.707C295.307 791.707 299.098 788.374 300.004 784.994C300.803 782.011 295.611 774.041 294.997 769.883L294.316 769.7C293.362 770.905 291.622 772.244 289.761 773.605L291.091 780.107L289.719 780.388L288.516 774.508C285.664 776.575 282.867 778.632 282.424 780.283C281.518 783.664 283.136 788.445 283.136 788.445L295.307 791.707Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6" clipPath="url(#clip3_0_1)">
          <path
            d="M1174.84 235.911C1175.34 235.054 1176.44 234.757 1177.3 235.252L1194.45 245.152C1195.3 245.647 1195.6 246.754 1195.11 247.611C1194.61 248.468 1193.5 248.765 1192.65 248.27L1175.5 238.37C1174.64 237.875 1174.35 236.768 1174.84 235.911ZM1150.9 271.973L1175.84 286.373C1176.7 286.868 1177.81 286.572 1178.3 285.714C1178.8 284.857 1178.5 283.75 1177.64 283.255L1152.7 268.855C1151.84 268.36 1150.74 268.657 1150.24 269.514C1149.75 270.372 1150.04 271.478 1150.9 271.973ZM1177.73 288.503L1148.11 271.403C1146.4 270.413 1144.19 271.006 1143.2 272.72C1142.21 274.435 1142.8 276.648 1144.51 277.638L1174.13 294.738C1175.85 295.728 1178.06 295.135 1179.05 293.42C1180.04 291.706 1179.45 289.493 1177.73 288.503ZM1182.04 223.44L1181.62 225.276L1200.93 236.422L1202.31 235.14L1195.64 226.892L1197.78 223.175L1199.34 224.075L1201.14 220.958L1199.58 220.058L1200.48 218.499L1197.36 216.699L1196.46 218.258L1194.91 217.358L1193.11 220.475L1194.66 221.375L1192.52 225.092L1182.04 223.44ZM1193.34 243.473L1200.24 237.063L1181.41 226.194L1179.31 235.373L1193.34 243.473ZM1176.59 240.037C1162.9 263.249 1159.45 264.971 1155.79 269.602L1175.45 280.951C1177.63 275.469 1177.4 271.623 1190.66 248.161L1176.59 240.037Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6" clipPath="url(#clip4_0_1)">
          <path
            d="M1150.32 610.174L1149.73 611.664L1144.51 609.622C1143.2 610.866 1142.85 612.888 1143.79 614.519C1144.8 616.27 1146.94 616.968 1148.77 616.223L1152.84 619.273C1152.32 622.292 1154.19 625.274 1157.2 626.082C1160.4 626.938 1163.69 625.035 1164.55 621.84L1165.32 622.047C1164.85 623.801 1163.73 625.268 1162.16 626.176C1161.23 626.707 1160.22 627.003 1159.19 627.069C1158.84 627.092 1158.49 627.085 1158.13 627.053C1158.1 627.05 1158.07 627.045 1158.03 627.042C1157.69 627.006 1157.34 626.947 1156.99 626.855C1156.69 626.774 1156.4 626.669 1156.12 626.551C1156.06 626.524 1156 626.492 1155.93 626.464C1155.71 626.36 1155.49 626.248 1155.28 626.124C1154.1 625.416 1153.16 624.377 1152.59 623.156C1147.87 625.044 1142.36 623.239 1139.74 618.705L1125.3 628.891L1136.5 648.29C1143.4 644.307 1150.44 642.734 1156.6 641.795C1151.82 640.283 1147.56 637.053 1144.86 632.376L1145.55 631.976C1151.07 641.526 1163.32 644.81 1172.87 639.296C1182.43 633.782 1185.71 621.526 1180.2 611.976L1179.8 611.283L1172.87 615.283L1172.47 614.59L1175.24 612.99L1172.44 608.14C1170.89 605.462 1167.47 604.544 1164.79 606.091C1163.52 606.823 1153.01 598.109 1147.3 601.405C1144.96 602.757 1143.97 605.544 1144.79 608.013L1150.32 610.174ZM1169.87 611.919L1169.09 613.318L1165.01 611.054L1165.79 609.655L1169.87 611.919ZM1148.57 603.859L1151.15 605.138L1150.44 606.572L1147.86 605.293L1148.57 603.859ZM1137.81 652.154C1138.25 652.916 1137.98 653.899 1137.22 654.339C1136.46 654.779 1135.47 654.516 1135.03 653.754L1119.83 627.426C1119.39 626.664 1119.66 625.681 1120.42 625.241C1121.18 624.801 1122.17 625.064 1122.61 625.826L1137.81 652.154ZM1134.37 660.603C1132.85 661.483 1130.88 660.956 1130 659.432L1112.4 628.948C1111.52 627.424 1112.05 625.457 1113.57 624.577C1115.09 623.697 1117.06 624.224 1117.94 625.748L1135.54 656.232C1136.42 657.756 1135.89 659.723 1134.37 660.603Z"
            fill="#086BDE"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M1039.31 789.889L1032.56 781.846L1036.31 778.696L1039.01 781.913L1042.23 779.214L1039.53 775.996L1045.96 770.597L1048.66 773.814L1051.88 771.115L1049.18 767.897L1052.94 764.748L1059.69 772.791C1060.68 773.971 1060.52 775.746 1059.34 776.736L1043.25 790.234C1042.07 791.224 1040.3 791.069 1039.31 789.889ZM1061.59 779.417C1062.18 778.922 1062.26 778.034 1061.76 777.445C1061.27 776.855 1060.38 776.777 1059.79 777.272L1043.7 790.771C1043.11 791.265 1043.04 792.153 1043.53 792.743C1044.03 793.333 1044.91 793.41 1045.5 792.915L1061.59 779.417ZM1065.32 821.977L1086.77 803.979C1087.36 803.484 1087.44 802.596 1086.94 802.006C1086.45 801.416 1085.56 801.339 1084.97 801.834L1063.52 819.832C1062.93 820.327 1062.85 821.214 1063.35 821.804C1063.84 822.394 1064.73 822.472 1065.32 821.977ZM1088.83 803.165L1064.16 823.863C1062.98 824.853 1062.83 826.628 1063.82 827.808C1064.81 828.987 1066.58 829.143 1067.76 828.153L1092.43 807.455C1093.61 806.465 1093.76 804.69 1092.77 803.51C1091.78 802.33 1090.01 802.175 1088.83 803.165ZM1083.59 802.08C1079.81 799.538 1076.24 800.146 1060.43 781.303L1047.56 792.102C1063.37 810.944 1062.15 814.354 1064 818.514L1083.59 802.08Z"
            fill="#086BDE"
          />
        </g>
        <defs>
          <clipPath id="clip0_0_1">
            <rect width="60" height="60" fill="white" transform="translate(100.357) rotate(25)" />
          </clipPath>
          <clipPath id="clip1_0_1">
            <rect
              width="50"
              height="50"
              fill="white"
              transform="translate(79.3553 311) rotate(45)"
            />
          </clipPath>
          <clipPath id="clip2_0_1">
            <rect
              width="50"
              height="50"
              fill="white"
              transform="translate(0 764.139) rotate(-40)"
            />
          </clipPath>
          <clipPath id="clip3_0_1">
            <rect
              width="90"
              height="90"
              fill="white"
              transform="translate(1162.2 191.202) rotate(30)"
            />
          </clipPath>
          <clipPath id="clip4_0_1">
            <rect
              width="80"
              height="80"
              fill="white"
              transform="translate(1165.63 572.349) rotate(60)"
            />
          </clipPath>
        </defs>
      </svg>

      <IconSalesrook width={450} height={60} />
      <Grid container justifyContent={'center'}>
        <Grid md={6} sm={11} lg={4} item sx={{ zIndex: 1 }}>
          <Paper sx={{ width: '100%', mt: 5 }}>
            {loading && <LinearProgress />}
            <Box sx={{ p: 6 }}>
              <TextView $fontSize={28}>Login</TextView>
              <TextView $fontSize={14} $fontWeight={400} mt={2} mb={2}>
                SalesRook SuperAdmin Login
              </TextView>
              <TextField
                name="email"
                label="Email"
                fullWidth
                required
                type={'email'}
                onChange={handleChange('email')}
                placeholder={'name@company.com'}
                errorMessage={errors.email}
                value={values.email}
              />
              <TextField
                name="password"
                label="Password"
                fullWidth
                required
                type="password"
                onChange={handleChange('password')}
                placeholder={'Min. 6 characters'}
                errorMessage={errors.password}
                value={values.password}
              />
              {error && (
                <Box mt={1}>
                  <Alert severity={'error'} title={'Error'} message={error} />{' '}
                </Box>
              )}
              <Box mt={4}>
                <Button onClick={() => onLogin()}>Login</Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </StyledLogin>
  );
};

export default Login;
