import fetchAPI from '../../fetchAPI';

export const callUpdateCoreOfficeAddress = async (
  props: CoreOfficeAddress
): Promise<iResponse<Pagination<CoreOfficeAddress>>> => {
  const { locationId } = props;

  const response: iResponse<Pagination<CoreOfficeAddress>> = await fetchAPI({
    method: `PUT`,
    route: `core/location/${locationId}/offices-address`,
    data: props
  });

  return response;
};

export default callUpdateCoreOfficeAddress;
