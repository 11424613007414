import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callDeleteCompanyToken, callGetAllCompanyToken, callGetCoreDetailedList } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, AlertPopUp, TextField, TextView } from '@atoms';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';

type CoreAccountsProps = {
  startDate?: Date | null;
  endDate?: Date | null;
};

const CoreAccounts: React.FC<CoreAccountsProps> = (props: CoreAccountsProps) => {
  const {
    state: {
      companyTokenPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);
  const [selectstatus, setSelectStatus] = useState<string>('b');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [companyTokens, setCompanyToken] = useState<Pagination<CoreLocation>>();
  const [search, setSearch] = useState<string>();

  const api = async () => {
    setLoading(true);
    const start = props.startDate
      ? DateTime.fromJSDate(props.startDate).toISO({
          suppressMilliseconds: true,
          includeOffset: false
        })
      : undefined;
    const end = props.endDate
      ? DateTime.fromJSDate(props.endDate).toISO({
          suppressMilliseconds: true,
          includeOffset: false
        })
      : undefined;

    const { data, code } = await callGetCoreDetailedList({
      status: selectstatus == 'b' ? undefined : selectstatus,
      companyName: search,
      pageNumber: page,
      startDate: start,
      endDate: end,
      pageSize: perPage,
      sortBy: orderBy
    });
    if (code == 200) {
      setCompanyToken(data);
    }
    setLoading(false);
  };

  const handleChangestatus = (event: SelectChangeEvent) => {
    setSelectStatus(event.target.value);
  };

  useEffect(() => {
    api();
  }, [page, perPage, search, orderBy, props.startDate, props.endDate, selectstatus]);

  const rowData: TableRow[] = companyTokens ? generateRows(companyTokens.data) : [];

  return (
    <Box>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 1
        }}
      >
        <TextView display={'initial'} $fontSize={22}>
          Core Accounts
        </TextView>
        <Stack direction="row" spacing={1} alignItems={'end'}>
          <Select
            value={selectstatus}
            onChange={handleChangestatus}
            displayEmpty
            size="small"
            sx={{ backgroundColor: '#ffffff', fontSize: 14, height: '50' }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem key="all" value="b">
              All
            </MenuItem>
            <MenuItem key="live" value="live">
              Live
            </MenuItem>
            <MenuItem key="staging" value="staging">
              Staging
            </MenuItem>
          </Select>
          <TextField
            label={'Search Name'}
            name={'Search Name'}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Stack>
      </Box>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}

      <Table
        name="company-token"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={companyTokens}
      />
    </Box>
  );
};

export default CoreAccounts;
