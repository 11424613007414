import React from 'react';
import { Grid } from '@mui/material';

import { CommonCard } from '@organisms';

import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconPeople } from '@assets/svg/people.svg';

interface CardListProps {
  statistics?: UnifedUserStatistics;
}

const CardList: React.FC<CardListProps> = ({ statistics }: CardListProps) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={12} md={4}>
        <CommonCard
          title="Total Unified Users"
          count={`${statistics?.totalUnifiedCount ?? 0}`}
          icon={<IconTeam />}
          bgColor="#CFDBD5"
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <CommonCard
          title="Total Core Users"
          count={`${statistics?.coreUsersCount ?? 0}`}
          icon={<IconPeople />}
          bgColor="#CFDBD5"
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <CommonCard
          title="Total Negotiator Users"
          count={`${statistics?.negotiatorUsersCount ?? 0}`}
          icon={<IconPeople />}
          bgColor="#CFDBD5"
        />
      </Grid>
    </Grid>
  );
};

export default CardList;
