import fetchAPI from '../fetchAPI';

interface callGetAllCompanyTokenProps {
  type?: string;
  status?: string;
  companyName?: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string | string[];
}

export const callGetAllCompanyToken = async (
  props: callGetAllCompanyTokenProps
): Promise<iResponse<Pagination<CompanyToken>>> => {
  const { pageNumber, pageSize, companyName, sortBy, type, status } = props;

  const queryParams = new URLSearchParams();
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (companyName) {
    queryParams.append('companyName', companyName);
  }
  if (type) {
    queryParams.append('type', type);
  }
  if (status) {
    queryParams.append('status', status);
  }
  if (sortBy) {
    if (Array.isArray(sortBy)) {
      sortBy.forEach((sort) => queryParams.append('sortBy', sort));
    } else {
      queryParams.append('sortBy', sortBy);
    }
  }

  const queryString = queryParams.toString();
  const response: iResponse<Pagination<CompanyToken>> = await fetchAPI({
    method: `GET`,
    route: `company-token`,
    queryString
  });

  return response;
};

export default callGetAllCompanyToken;
