import React, { useState, useContext } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Box,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Button as LogoutButton,
  Divider
} from '@mui/material';
import { TextView, TextField, Button, Alert, AlertPopUp } from '@atoms';
import { ApplicationContext, callUpdateUser } from '@particles';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../../firebase';

const MyProfile: React.FC<RouteComponentProps> = () => {
  const {
    state: { user },
    dispatch
  } = useContext(ApplicationContext);

  const [success, setSuccess] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [logout, setLogout] = useState<boolean>(false);

  const [values, setValues] = useState({
    name: user?.name ?? '',
    email: user?.email ?? ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: ''
  });

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateFields = () => {
    let error = false;

    setErrors({
      name: '',
      email: ''
    });

    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (values.name.length <= 0) {
      error = true;
      setErrors((state) => ({ ...state, name: 'Name is Required' }));
    }

    if (!pattern.test(values.email)) {
      error = true;
      setErrors((state) => ({ ...state, email: 'Enter a valid email address' }));
    }

    return error;
  };

  const onSave = async () => {
    if (validateFields()) {
      return;
    }

    if (!user) {
      return;
    }

    setLoading(true);

    const { code, data, error } = await callUpdateUser(user.id, {
      name: values.name
    });

    if (code == 200 && data) {
      dispatch({ key: 'user', value: data, type: 'set' });
      localStorage.setItem('user', JSON.stringify(user));
      setSuccess('Data update successfully!');
      setTimeout(() => {
        setSuccess(undefined);
      }, 3000);
    } else if (error) {
      alert(error.message);
    }

    setLoading(false);
  };

  return (
    <Box>
      <TextView $fontSize={22} $fontWeight={600}>
        My Profile
      </TextView>
      {loading && <LinearProgress sx={{ mt: 1 }} />}
      <Paper sx={{ mt: 2, pr: 3, pl: 3, height: 500 }} variant="outlined">
        <TextField
          name="name"
          label="Name"
          fullWidth
          onChange={handleChange('name')}
          placeholder={'Your name'}
          errorMessage={errors.name}
          value={values.name}
        />
        <TextField
          name="email"
          label="Email"
          type={'email'}
          fullWidth
          onChange={handleChange('email')}
          placeholder={'name@company.com'}
          disabled
          errorMessage={errors.email}
          value={values.email}
        />
        <Stack direction={'row'} alignContent="center" justifyContent={'space-between'} mt={2}>
          <TextView
            $fontWeight={400}
            $fontColor={'rgba(8, 107, 222, 1)'}
            onClick={() => {
              setLoading(true);
              sendPasswordResetEmail(auth, values.email)
                .then(() => {
                  // Password reset email sent!
                  // ..
                  setLoading(false);
                  setSuccess('Password reset email sent!');
                  setTimeout(() => {
                    setSuccess(undefined);
                  }, 3000);
                })
                .catch((error) => {
                  const errorMessage = error.message;
                  setSuccess(errorMessage);
                  setTimeout(() => {
                    setSuccess(undefined);
                  }, 3000);
                  setLoading(false);
                  // ..
                });
            }}
            sx={{ textDecoration: 'underline' }}
          >
            Reset Password
          </TextView>
        </Stack>

        {success && (
          <Box sx={{ mt: 2 }}>
            <Alert message={success} />
          </Box>
        )}

        <Button sx={{ mt: 4 }} onClick={onSave}>
          Save Profile
        </Button>

        <LogoutButton
          sx={{ mt: 4, ml: 2 }}
          size="large"
          color="error"
          variant="outlined"
          onClick={() => setLogout(true)}
        >
          Logout
        </LogoutButton>
      </Paper>
      <AlertPopUp
        open={logout}
        title={'Logout'}
        message={'Are you sure, you want to logout?'}
        onSuccess={() => {
          setLogout(false);
          localStorage.clear();
          dispatch({ key: 'user', value: undefined, type: 'set' });
          navigate('/login');
        }}
        onClose={() => {
          setLogout(false);
        }}
      />
    </Box>
  );
};

export default MyProfile;
