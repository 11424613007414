import fetchAPI from '../../fetchAPI';

export const callCreateCoreOfficeAddress = async (
  props: CoreOfficeAddress
): Promise<iResponse<Pagination<CoreOfficeAddress>>> => {
  const { locationId } = props;

  const response: iResponse<Pagination<CoreOfficeAddress>> = await fetchAPI({
    method: `POST`,
    route: `core/location/${locationId}/offices-address`,
    data: props
  });

  return response;
};

export default callCreateCoreOfficeAddress;
