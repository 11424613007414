import React, { ReactChild } from 'react';
import { TextView } from '../../../../../atoms';
import { Stack, Box } from '@mui/material';

import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconMessage } from '@assets/svg/message.svg';
import { ReactComponent as IconPeople } from '@assets/svg/people.svg';

interface CardProps {
  index: number;
  title: string;
  count: string;
  icon: ReactChild;
  isUser?: boolean;
  activeInactive?: ActiveInactive;
}

const Card = (props: CardProps) => {
  const { title, count, icon, isUser, activeInactive, index } = props;

  const totalActive = activeInactive?.active ?? 0;
  const totalInactive = activeInactive?.inactive ?? 0;

  return (
    <Stack
      direction={'row'}
      sx={{ backgroundColor: '#CFDBD5', borderRadius: 1, p: 1.5, mt: index > 0 ? 2 : 0 }}
    >
      <Stack sx={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <Stack>
          <TextView $fontWeight={400} $fontSize={16}>
            {title}
          </TextView>
          <TextView $fontSize={32}>{count}</TextView>
        </Stack>

        {isUser && (
          <Stack ml={3} mb={1}>
            <Stack direction={'row'} alignItems={'center'}>
              <TextView $fontWeight={400} $fontSize={14} mr={2}>
                Active
              </TextView>
              <TextView $fontWeight={600} $fontSize={16} mr={1}>
                {totalActive}
              </TextView>
              <TextView $fontWeight={400} $fontSize={14}>
                {isNaN((totalActive / (totalActive + totalInactive)) * 100)
                  ? ''
                  : `${((totalActive / (totalActive + totalInactive)) * 100).toFixed(0)}%`}
              </TextView>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <TextView $fontWeight={400} $fontSize={14} mr={1}>
                Inactive
              </TextView>
              <TextView $fontWeight={600} $fontSize={16} mr={1}>
                {totalInactive}
              </TextView>
              <TextView $fontWeight={400} $fontSize={14}>
                {isNaN((totalInactive / (totalActive + totalInactive)) * 100)
                  ? ''
                  : `${((totalInactive / (totalActive + totalInactive)) * 100).toFixed(0)}%`}
              </TextView>
            </Stack>
          </Stack>
        )}
      </Stack>
      {icon}
    </Stack>
  );
};

interface CardListProps {
  totalContacts: number;
  totalMessages: number;
  activeInactive: ActiveInactive;
}

const CardList: React.FC<CardListProps> = ({
  totalContacts,
  totalMessages,
  activeInactive
}: CardListProps) => {
  return (
    <Box>
      <Card
        index={0}
        title="Total Contacts Engaged"
        count={`${totalContacts}`}
        icon={<IconPeople />}
      />
      <Card index={1} title="Total Messages" count={`${totalMessages}`} icon={<IconMessage />} />
      <Card
        index={2}
        title="Total Licences"
        count={`${activeInactive.active + activeInactive.inactive}`}
        icon={<IconTeam />}
        isUser
        activeInactive={activeInactive}
      />
    </Box>
  );
};

export default CardList;
