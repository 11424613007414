import fetchAPI from '../fetchAPI';

export const callGetUnifiedUser = async (): Promise<iResponse<UnifedUserStatistics>> => {

  const response: iResponse<UnifedUserStatistics> = await fetchAPI({
    method: `GET`,
    route: `user/unified-statistics`
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetUnifiedUser;
