const columns: TableColumn[] = [
  {
    Header: `Office ID`,
    accessor: `officeId`,
    dbKey: `officeId`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Location`,
    accessor: `location`,
    dbKey: `location`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Reapit Email`,
    accessor: `reapitEmail`,
    dbKey: `reapitEmail`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Read Email`,
    accessor: `readEmail`,
    dbKey: `readEmail`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Robot Email`,
    accessor: `robotEmail`,
    dbKey: `robotEmail`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Mortgage Email`,
    accessor: `mortgageEmail`,
    dbKey: `mortgageEmail`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Postal Codes`,
    accessor: `postalCodes`,
    dbKey: `postalCodes`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: ``,
    accessor: `action`,
    dbKey: `action`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
