import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, TextView } from '@atoms';
import { RouteComponentProps, useLocation } from '@reach/router';
import { Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { callValidateGhlCode } from '@particles';
import Divider from '@mui/material/Divider';

const OAuthGhl: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      callValidateGhlCode(code as string)
        .then((res) => {
          console.log(res);
          window.close();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [location]);

  const [integrationInProgress, setIntegrationInProgress] = useState<boolean>(false);

  const onSubmit = () => {
    setIntegrationInProgress(true);

    const popup = window.open(process.env.REACT_APP_API_BASE + '/core/oauth', 'Ghl Integration');

    if (!popup || popup.closed || typeof popup.closed == 'undefined') {
      alert('Popup blocked! Please enable popups for this site.');
    }

    // Check if the window was successfully opened
    if (popup) {
      // Listen for window close event
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
          // Window is closed, integration is complete
          setIntegrationInProgress(false);
        }
      }, 1000); // Check every second if the window is closed
    } else {
      console.error('Failed to open login window');
      // Handle error, if needed
      setIntegrationInProgress(false);
    }
  };

  return (
    <Box m={4}>
      <Paper>
        <Stack mt={3} p={2} alignContent={'center'} alignItems={'center'} minHeight={300}>
          <TextView $fontSize={22}>Login to GoHighLevel</TextView>
          <Divider />
          <TextView $fontSize={18} mt={2} $fontWeight={400}>
            Use your GoHighLevel account to login and access the platform.
          </TextView>
          <Button sx={{ m: 2 }} size="small" onClick={() => onSubmit()}>
            Login with GoHighLevel
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default OAuthGhl;
