import fetchAPI from '../fetchAPI';

export const callAddKey = async (data: any): Promise<iResponse<{ data: Keys }>> => {
  const response: iResponse<{ data: Keys }> = await fetchAPI({
    method: `POST`,
    route: `/api-key`,
    data
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callAddKey;
