import { Button } from '@mui/material';
import React, { useState } from 'react';

interface Props {
  text: string;
  maxLength: number;
}

const ReadMoreLess: React.FC<Props> = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };
  return (
    <div>
      {isTruncated ? (
        <div>
          {' '}
          {text?.length > maxLength ? text.slice(0, maxLength) + '...' : text}
          {text?.length > maxLength ? (
            <Button
              onClick={toggleTruncate}
              style={{
                color: '#086bde',
                fontWeight: 'bold'
              }}
            >
              Read More...
            </Button>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div>
          {text}
          <Button
            onClick={toggleTruncate}
            style={{
              fontWeight: 'bold'
            }}
          >
            Read Less...
          </Button>
        </div>
      )}
    </div>
  );
};
export default ReadMoreLess;
