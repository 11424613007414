import React from 'react';
import { Box, Grid, LinearProgress } from '@mui/material';

import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconPeople } from '@assets/svg/people.svg';
import { CommonCard } from '@organisms';
import { Card, Text } from '@atoms';

interface CardListProps {
  loading: boolean;
  dashboardCount?: CoreDashboardNumbers;
}

const CardList: React.FC<CardListProps> = ({ dashboardCount, loading }: CardListProps) => {
  return (
    <>
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <Grid container spacing={2}>
        {dashboardCount?.total_live_locations != undefined && (
          <Grid md={4} sm={12} item>
            <CommonCard
              title="Live Accounts"
              count={`${dashboardCount.total_live_locations}`}
              icon={<IconTeam />}
            />
          </Grid>
        )}

        {dashboardCount?.total_contacts != undefined && (
          <Grid md={4} sm={12} item>
            <CommonCard
              title="Total Contacts"
              count={`${dashboardCount.total_contacts}`}
              icon={<IconPeople />}
            />
          </Grid>
        )}

        {dashboardCount?.total_users != undefined && (
          <Grid md={4} sm={12} item>
            <CommonCard
              title="Total Users"
              count={`${dashboardCount.total_users}`}
              icon={<IconPeople />}
            />
          </Grid>
        )}
        {dashboardCount?.total_sms_count != undefined && (
          <Grid md={4} sm={12} item>
            <Card sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)' }}>
              <Text $fontWeight={400}>Twilio</Text>
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_sms_count}
                  <Text $fontWeight={400} $fontSize={12}>
                    SMS
                  </Text>
                </Text>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_call_count}
                  <Text $fontWeight={400} $fontSize={12}>
                    Call
                  </Text>
                </Text>
              </Box>
            </Card>
          </Grid>
        )}

        {dashboardCount?.total_events_mortgage != undefined && (
          <Grid md={4} sm={12} item>
            <Card sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)' }}>
              <Text $fontWeight={400}>Events</Text>
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_events_mortgage}
                  <Text $fontWeight={400} $fontSize={12}>
                    Mortgage
                  </Text>
                </Text>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_events_qualified}
                  <Text $fontWeight={400} $fontSize={12}>
                    Qualified
                  </Text>
                </Text>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_events_valuation}
                  <Text $fontWeight={400} $fontSize={12}>
                    Valuation
                  </Text>
                </Text>
              </Box>
            </Card>
          </Grid>
        )}

        {dashboardCount?.total_waba_template != undefined && (
          <Grid md={4} sm={12} item>
            <Card sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)' }}>
              <Text $fontWeight={400}>WABA</Text>
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_waba_template}
                  <Text $fontWeight={400} $fontSize={12}>
                    Template
                  </Text>
                </Text>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_waba_inbound}
                  <Text $fontWeight={400} $fontSize={12}>
                    Inbound
                  </Text>
                </Text>
                <Text $fontSize={26} justifyItems={'center'}>
                  {dashboardCount.total_waba_outbound}
                  <Text $fontWeight={400} $fontSize={12}>
                    Outbound
                  </Text>
                </Text>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CardList;
