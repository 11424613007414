import React, { useContext, useLayoutEffect, useRef } from 'react';
import { TableInstance } from 'react-table';
import MaterialHead from '@mui/material/TableHead';
import MaterialRow from '@mui/material/TableRow';
import MaterialCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { TableContext } from '../../../particles';

//import {getQueryParameters} from 'helpers';
//import {decodeQueryParams} from 'api';

export type Order = `asc` | `desc`;

export interface TableHeadProps {
  name: TableType;
  /** Table instance */
  tableInstance: TableInstance<TableRow>;
  /** Relays back to the main table component the current height of the header */
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
}

export const TableHead: React.FC<TableHeadProps> = ({
  tableInstance,
  setHeaderHeight,
  name
}: TableHeadProps) => {
  const { allColumns, headerGroups } = tableInstance;
  const {
    dispatch,
    state: {
      unifiedUsersPagination,
      coreUsersPagination,
      coreAccountsPagination,
      coreContactsPagination,
      coreOfficesPagination,
      teamPagination,
      billingPagination,
      accountPerformancePagination,
      companyTokenPagination,
      openAiPagination,
      twilioPagination,
      srtPagination,
      companyTransactionsLogsPagination,
      apiKeys
    }
  } = useContext(TableContext);
  const ref = useRef<HTMLTableSectionElement>(null);

  //const queryParameters = getQueryParameters();
  //const {orderBy: queryOrderBy} = decodeQueryParams(queryParameters);

  // const initialSortedDesc = queryOrderBy ? queryOrderBy.indexOf(`-`) === 0 : true;
  // const initialOrderBy = queryOrderBy && initialSortedDesc ? queryOrderBy.slice(1) : queryOrderBy;

  const [isSortedDesc, setIsSortedDesc] = React.useState<boolean>(true);
  const [orderBy, setOrderBy] = React.useState<string>('');

  const handleHeaderClick = (columnId: string, index: number) => {
    let newOrderBy = orderBy;
    let newIsSortedDesc = isSortedDesc;

    if (!orderBy || newOrderBy !== columnId) {
      newOrderBy = columnId;
      newIsSortedDesc = true;
    } else if (newIsSortedDesc) {
      newIsSortedDesc = false;
    } else {
      newOrderBy = ``;
    }

    setOrderBy(newOrderBy);
    setIsSortedDesc(newIsSortedDesc);

    if (name === 'unified-users') {
      dispatch({
        type: `set`,
        key: `unifiedUsersPagination`,
        value: {
          page: 1,
          perPage: unifiedUsersPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'core_offices') {
      dispatch({
        type: `set`,
        key: `coreOfficesPagination`,
        value: {
          page: 1,
          perPage: coreOfficesPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'core_accounts') {
      dispatch({
        type: `set`,
        key: `coreAccountsPagination`,
        value: {
          page: 1,
          perPage: coreAccountsPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'core_users') {
      dispatch({
        type: `set`,
        key: `coreUsersPagination`,
        value: {
          page: 1,
          perPage: coreUsersPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'core_contacts') {
      dispatch({
        type: `set`,
        key: `coreUsersPagination`,
        value: {
          page: 1,
          perPage: coreContactsPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    }else if (name === 'team') {
      dispatch({
        type: `set`,
        key: `teamPagination`,
        value: {
          page: 1,
          perPage: teamPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'billing') {
      dispatch({
        type: `set`,
        key: `billingPagination`,
        value: {
          page: 1,
          perPage: billingPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'account_performance') {
      dispatch({
        type: `set`,
        key: `accountPerformancePagination`,
        value: {
          page: 1,
          perPage: accountPerformancePagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'company-token') {
      dispatch({
        type: `set`,
        key: `companyTokenPagination`,
        value: {
          page: 1,
          perPage: companyTokenPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'open-ai') {
      dispatch({
        type: `set`,
        key: `openAiPagination`,
        value: {
          page: 1,
          perPage: openAiPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'twilio') {
      dispatch({
        type: `set`,
        key: `twilioPagination`,
        value: {
          page: 1,
          perPage: twilioPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'srt') {
      dispatch({
        type: `set`,
        key: `srtPagination`,
        value: {
          page: 1,
          perPage: srtPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'company-transactions-logs') {
      dispatch({
        type: `set`,
        key: `companyTransactionsLogsPagination`,
        value: {
          page: 1,
          perPage: companyTransactionsLogsPagination.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    } else if (name === 'api-keys') {
      dispatch({
        type: `set`,
        key: `apiKeys`,
        value: {
          page: 1,
          perPage: apiKeys.perPage,
          orderBy: newOrderBy
            ? newIsSortedDesc
              ? `-${allColumns[index].dbKey}`
              : allColumns[index].dbKey
            : ``
        }
      });
    }
  };

  useLayoutEffect(() => {
    setHeaderHeight(ref.current ? ref.current.clientHeight : 0);
  }, [setHeaderHeight]);

  return (
    <MaterialHead ref={ref}>
      {headerGroups.map((headerGroup) => {
        const { key, ...getHeaderGroupProps } = headerGroup.getHeaderGroupProps();

        return (
          <MaterialRow key={key} {...getHeaderGroupProps}>
            {headerGroup.headers.map((column, index) => {
              const { key, ...getHeaderProps } = column.getHeaderProps();

              const isSorted = orderBy === column.id;
              const direction = isSortedDesc ? `desc` : `asc`;
              const sortTooltip = isSorted
                ? isSortedDesc
                  ? `Sorted descending`
                  : `Sorted ascending`
                : `Click to sort`;

              if (column.isSortable) {
                return (
                  <MaterialCell
                    key={key}
                    {...getHeaderProps}
                    onClick={() => handleHeaderClick(column.id, index)}
                  >
                    <Tooltip title={sortTooltip}>
                      <TableSortLabel
                        sx={{ fontSize: 16, fontWeight: 600 }}
                        active={isSorted}
                        direction={direction}
                      >
                        {column.render(`Header`)}
                      </TableSortLabel>
                    </Tooltip>
                  </MaterialCell>
                );
              } else {
                return (
                  <MaterialCell
                    sx={{ fontSize: 16, fontWeight: 600 }}
                    key={key}
                    {...getHeaderProps}
                  >
                    {column.render(`Header`)}
                  </MaterialCell>
                );
              }
            })}
          </MaterialRow>
        );
      })}
    </MaterialHead>
  );
};

export default TableHead;
