const columns: TableColumn[] = [
  {
    Header: `Name`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Email`,
    accessor: `email`,
    dbKey: `email`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Phone`,
    accessor: `phone`,
    dbKey: `phone`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Role`,
    accessor: `role`,
    dbKey: `role`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  }
];

export default columns;
