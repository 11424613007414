import { Theme } from '@emotion/react';
import { LinearProgress, SxProps } from '@mui/material';
import React from 'react';

import StyledButton from './button.style';

interface ButtonSharedProps {
  /** Is this the principal call to action on the page? */
  primary?: boolean;
  /** How large should the button be? */
  $hoverColor?: string;
  /** How large should the button be? */
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  /** Click handler */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** The size of the font */
  $fontSize?: number;
  /** The type of the button, used for form actions */
  type?: 'submit' | 'reset';
  /** Element placed before the children */
  startIcon?: React.ReactNode;
  /** Element placed after the children */
  endIcon?: React.ReactNode;
  /** If true, the button will take up the full width of its container */
  fullWidth?: boolean;
  /** If true, the button will be disabled */
  disabled?: boolean;
  /** The system prop that allows defining system overrides as well as additional CSS styles. */
  sx?: SxProps<Theme>;
  /** If true, a loading indicator will be shown inside the button */
  loading?: boolean;
}

interface ButtonChildrenProps extends ButtonSharedProps {
  /** Button contents, string only */
  label?: never;
  /** Button contents */
  children: React.ReactNode;
}

interface ButtonLabelProps extends ButtonSharedProps {
  /** Button contents, string only */
  label: string;
  /** Button contents */
  children?: never;
}

export type ButtonProps = ButtonLabelProps | ButtonChildrenProps;

export const Button: React.FC<ButtonProps> = ({
  primary = true,
  size = 'medium',
  variant = 'contained',
  $hoverColor,
  label,
  children,
  loading = false,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      color={primary ? 'primary' : 'secondary'}
      size={size}
      $hoverColor={$hoverColor}
      variant={variant}
      {...props}
      disabled={loading || props.disabled} // Disable button when loading
    >
      {loading && (
        <LinearProgress color="primary" style={{ position: 'absolute', width: '100%', bottom: 0 }} />
      )}
      {children ?? label}
    </StyledButton>
  );
};

export default Button;
