import { SxProps, Theme } from '@mui/material';
import React from 'react';

import StyledInputBase from './inputBase.style';

interface InputBaseSharedProps {
  /** The name of the element, used for identifying the element with formik */
  name: string;
  /** The short hint displayed in the input before the user enters a value. */
  placeholder?: string;
  /** Type of the input element */
  inputType?:
    | `text`
    | `password`
    | `number`
    | `search`
    | `url`
    | `tel`
    | `email`
    | `date`
    | `time`
    | `datetime-local`
    | `month`
    | `week`
    | `color`;
  /** If true, the input element will be disabled */
  disabled?: boolean;
  /** If true, the label is displayed as required and the `input` element` will be required */
  required?: boolean;
  /** If true, the label will be displayed in an error state */
  error?: boolean;
  /** If true, the input element will be focused during the first mount */
  autoFocus?: boolean;
  /** The width of the text field (disabled when fullWidth is true) */
  $width?: Sizes;
  /** If true, the input will take up the full width of its container */
  fullWidth?: boolean;
  /** If true, the label is shrunk and displayed above the input by default */
  $shrink?: boolean;
  /** Change handler */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
  /** The vertical size of the text field */
  size?: `small` | `medium`;
  /** End `InputAdornment` for this component. */
  endAdornment?: React.ReactNode;
  /** Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types). @default 'text' */
  type?: string;
}

export interface InputBaseSingleProps extends InputBaseSharedProps {
  /** The current element value. */
  value?: string | number;
  /** This component will not support multiple selections */
  multiple?: false;
}

export interface InputBaseMultipleProps extends InputBaseSharedProps {
  /** The current element value. */
  value?: string[] | number[];
  /** This component will support multiple selections */
  multiple?: true;
}

export type InputBaseProps = InputBaseSingleProps | InputBaseMultipleProps;

export const InputBase: React.FC<InputBaseProps> = ({
  $width = `md`,
  $shrink,
  ...props
}: InputBaseProps) => (
  <StyledInputBase $width={$width} $shrink={$shrink} autoFocus={false} {...props} />
);

export default InputBase;
