import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { CoreAccountTable } from './particles';
import { Box, Stack } from '@mui/material';

import { callGetCoreDashboard } from '@api';
import { DashboardHeader } from '@organisms';
import CardList from './particles/commonCard';

const NegotiatorDashboard: React.FC<RouteComponentProps> = () => {
  const [start, setStart] = useState<Date>(DateTime.local().minus({ days: 6 }).toJSDate());
  const [end, setEnd] = useState<Date>(new Date());
  const [loadingDashboard, setLoadingDashboard] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<CoreDashboardNumbers | undefined>(undefined);

  useEffect(() => {
    apiCalling();
  }, [start, end]);

  const apiCalling = async () => {
    if (start && end) {
      const startDate = DateTime.fromJSDate(start).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      const endDate = DateTime.fromJSDate(end).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });

      setLoadingDashboard(true);
      callGetCoreDashboard({ startDate, endDate })
        .then((res) => {
          if (res.data) {
            setDashboard(res.data.data);
          }
          setLoadingDashboard(false);
        })
        .finally(() => {
          setLoadingDashboard(false);
        });
    }
  };

  return (
    <Stack sx={{ m: 2 }}>
      <Box sx={{ mt: 1, mb: 2 }}>
        <DashboardHeader
          title={'Dashboard'}
          start={start}
          end={end}
          onSelectRange={(start, end) => {
            setStart(start);
            setEnd(end);
          }}
        />
      </Box>
      <CardList loading={loadingDashboard} dashboardCount={dashboard} />
      <Box sx={{ mt: 2, mb: 2 }}>
        <CoreAccountTable startDate={start} endDate={end} />
      </Box>
    </Stack>
  );
};

export default NegotiatorDashboard;
