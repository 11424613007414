import React, { Suspense, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { LoadingCircle } from '../../atoms';
import { SideNav } from '../../organisms';

import StyledAdmin from './admin.style';
import { ApplicationContext } from '@particles';
import { Errors404 } from '@pages';

export interface ErrorProps {
  /** Suspense error */
  error: Error;
}

export interface PageProps extends RouteComponentProps {
  /** Page content */
  children: React.ReactNode;
}

export const TemplatePage: React.FC<PageProps> = ({ children }: PageProps) => {
  const {
    state: { user }
  } = useContext(ApplicationContext);

  const isAdmin = user ? user.role.name === 'Admin' || user.role.name === 'Super Admin' : true;

  return (
    <StyledAdmin className="App">
      <Paper className="sidebar" square elevation={0}>
        <SideNav isSuperAdmin={false} />
      </Paper>
      <div className="container">
        <Paper className="main" square elevation={0}>
          <Box component="main" height="100%" display="flex" flexDirection="column">
            <Suspense fallback={<LoadingCircle size={150} />}>
              {isAdmin ? (
                React.isValidElement(children) && React.cloneElement(children)
              ) : (
                <Errors404 />
              )}
            </Suspense>
          </Box>
        </Paper>
      </div>
    </StyledAdmin>
  );
};

export default TemplatePage;
