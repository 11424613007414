import fetchAPI from '../fetchAPI';

export const callGetMarkupValues = async (): Promise<iResponse<Pagination<MarkUp>>> => {
  const response: iResponse<Pagination<MarkUp>> = await fetchAPI({
    method: `GET`,
    route: `markup`
  });
  return response;
};

export default callGetMarkupValues;
