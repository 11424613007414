import { routes } from '@particles';
import { DateTime } from 'luxon';

const generateRows = (contacts: CoreContact[]): TableRow[] =>
  contacts.map((token) => {
    const payload: TableRow = {
      name: token.name ?? '',
      phone: token.phone ?? '',
      email: token.email ?? '',
      source: token.source ?? ''
      // linkData: {
      //   to: `${routes.SA_Core}/${token.locationId}`
      // }
    };
    return payload;
  });

export default generateRows;
