import { DateTime } from 'luxon';
import { routes } from '../../../../particles/common';

const generateRows = (
  users: UnifedUser[],
  // onAction: (token: UnifedUser, value: unknown, type: 'edit' | 'delete') => void
): TableRow[] =>
  users.map((user) => {
    // const edit: TableCellAction = {
    //   type: `action`,
    //   data: [
    //     {
    //       type: 'edit',
    //       linkData: {
    //         onClick: (value) => {
    //           onAction(token, value as boolean, 'edit');
    //         }
    //       }
    //     },
    //     {
    //       type: 'delete',
    //       linkData: {
    //         onClick: (value) => {
    //           onAction(token, value as boolean, 'delete');
    //         }
    //       }
    //     }
    //   ]
    // };

    let type: TableCellChip = {
      type: 'chip',
      data: [
        {
          text: 'C',
          backgroundColor: '#F5CB5C'
        }
      ]
    };
    if (user.locationId && user.negotiatorId) {
      type = {
        type: 'chip',
        data: [
          {
            text: 'N',
            backgroundColor: '#086BDE'
          },
          {
            text: 'C',
            backgroundColor: '#F5CB5C'
          }
        ]
      };
    } else if (user.locationId) {
      type = {
        type: 'chip',
        data: [
          {
            text: 'C',
            backgroundColor: '#F5CB5C'
          }
        ]
      };
    } else if (user.negotiatorId) {
      type = {
        type: 'chip',
        data: [
          {
            text: 'N',
            backgroundColor: '#086BDE'
          }
        ]
      };
    }

    const payload: TableRow = {
      type,
      name: user.name ?? '',
      email: user.email ?? '',
      phone: user.phone ?? '',
      // linkData: {
      //   to: `${routes.SA_BillingTokens}/${token.id}`
      // }
    };

    return payload;
  });

export default generateRows;
