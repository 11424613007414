import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callDeleteCompanyToken, callGetAllCompanyToken } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, AlertPopUp, Button, TextField, TextView } from '@atoms';
import { RouteComponentProps } from '@reach/router';
import AddCompanyToken from './add-account';
import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';

const CompanyToken: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      companyTokenPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [openAddToken, setOpenAddToken] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [deleteToken, setDeleteToken] = useState<string>();
  const [selectedCompanyToken, setSelectedCompanyToken] = useState<CompanyToken>();
  const [companyTokens, setCompanyToken] = useState<Pagination<CompanyToken>>();
  const [search, setSearch] = useState<string>();
  const [selectType, setSelectType] = useState<string>('b');
  const [selectstatus, setSelectStatus] = useState<string>('b');

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetAllCompanyToken({
      type: selectType == 'b' ? undefined : selectType,
      status: selectstatus == 'b' ? undefined : selectstatus,
      companyName: search,
      pageNumber: page,
      pageSize: perPage,
      sortBy: orderBy
    });
    if (code == 200) {
      setCompanyToken(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, search, orderBy, selectType, selectstatus]);

  const onAction = async (token: CompanyToken, value: unknown, type: string) => {
    if (type == 'edit') {
      setOpenAddToken(true);
      setSelectedCompanyToken(token);
    } else if (type == 'delete') {
      setDeleteToken(token.id);
    }
  };

  const deleteApi = async (id?: string) => {
    if (!id) {
      return;
    }

    setLoading(true);
    const { code, error } = await callDeleteCompanyToken(id);
    if (code >= 400 && error) {
      setError(error.message);
      setTimeout(() => {
        setError(undefined);
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
      api();
    }
  };

  const rowData: TableRow[] = companyTokens ? generateRows(companyTokens.data, onAction) : [];

  const handleChange = (event: SelectChangeEvent) => {
    setSelectType(event.target.value);
  };
  const handleChangestatus = (event: SelectChangeEvent) => {
    setSelectStatus(event.target.value);
  };

  return (
    <Box m={4}>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextView display={'initial'} $fontSize={30}>
          Billing Accounts Current Usage
        </TextView>
        <Box sx={{ flex: 1 }} />
        <Button onClick={() => setOpenAddToken(true)}>{'Add Account'}</Button>
      </Box>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}
      <Stack direction={'row'} sx={{ mb: 2 }}>
        <TextField
          label={'Search Account Name'}
          name={'Search Account Name'}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Stack mt={1.5} ml={2}>
          <TextView $fontWeight={400} $fontSize={14}>
            Account Type
          </TextView>
          <Select
            value={selectType}
            onChange={handleChange}
            displayEmpty
            size={'small'}
            sx={{ backgroundColor: '#ffffff', fontSize: 14, mt: 1 }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem key={0} value={'b'}>
              All
            </MenuItem>
            <MenuItem key={0} value={'c'}>
              Core
            </MenuItem>
            <MenuItem key={0} value={'r'}>
              Negotiator
            </MenuItem>
          </Select>
        </Stack>
        <Stack mt={1.5} ml={2}>
          <TextView $fontWeight={400} $fontSize={14}>
            Status Type
          </TextView>
          <Select
            value={selectstatus}
            onChange={handleChangestatus}
            displayEmpty
            size="small"
            sx={{ backgroundColor: '#ffffff', fontSize: 14, mt: 1 }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem key="all" value="b">
              All
            </MenuItem>
            <MenuItem key="live" value="live">
              Live
            </MenuItem>
            <MenuItem key="staging" value="staging">
              Staging
            </MenuItem>
          </Select>
        </Stack>
      </Stack>

      <Table
        name="company-token"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={companyTokens}
      />

      <AddCompanyToken
        open={openAddToken}
        selectedCompanyToken={selectedCompanyToken}
        onClose={() => {
          setOpenAddToken(false);
          setSelectedCompanyToken(undefined);
        }}
        onSuccess={() => {
          setOpenAddToken(false);
          setSelectedCompanyToken(undefined);
          api();
        }}
      />

      <AlertPopUp
        open={deleteToken ? true : false}
        title={'Delete'}
        message={'Are you sure, you want to delete company token?'}
        onSuccess={() => {
          deleteApi(deleteToken);
          setDeleteToken(undefined);
        }}
        onClose={() => {
          setDeleteToken(undefined);
        }}
      />
    </Box>
  );
};

export default CompanyToken;
