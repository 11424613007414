import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Stack } from '@mui/material';
import { TextView } from '../../atoms';
import { DateTime } from 'luxon';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { callGetEngagementOverTime } from '@particles';
import Team from '../../pages/admin/team/index';

interface CustomizedAxisTickProps {
  x: number;
  y: number;
  payload: { value: any };
}

interface EngagementOverTimeProps {
  start?: Date;
  end?: Date;
  companyId?: string;
  team?: Team;
}

const EngagementOverTime: React.FC<EngagementOverTimeProps> = ({
  start,
  end,
  companyId,
  team
}: EngagementOverTimeProps) => {
  const [engagementOverTime, setEngagementOverTime] = useState<EngagementOverTime>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const api = async () => {
      const startDate = start
        ? DateTime.fromJSDate(start).toISO({
            suppressMilliseconds: true,
            includeOffset: false
          })
        : undefined;
      const endDate = end
        ? DateTime.fromJSDate(end).toISO({
            suppressMilliseconds: true,
            includeOffset: false
          })
        : undefined;
      setLoading(true);
      if (startDate && endDate) {
        const resEngagementOverTime = await callGetEngagementOverTime({
          from: startDate,
          to: endDate,
          companyId: companyId,
          teamId: team?.id
        });
        if (resEngagementOverTime.code === 200) {
          setEngagementOverTime(resEngagementOverTime.data);
        }
        setLoading(false);
      }
    };

    api();
  }, [start, end, companyId, team]);

  const aggregation = engagementOverTime?.data.aggregation ?? 'days';
  const values = engagementOverTime?.data.values ?? [];

  const CustomizedAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ fontFamily: 'Montserrat', fontSize: 8, fontWeight: 400 }}
        >
          {DateTime.fromISO(payload.value).toFormat(
            aggregation === 'days' ? 'dd/LL/yyyy' : 'LLL, yyyy'
          )}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          textAnchor="end"
          fill="#666"
          style={{ fontFamily: 'Montserrat', fontSize: 10, fontWeight: 400 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', p: 1, borderRadius: 1, height: 300 }}>
      <TextView $fontSize={24} sx={{ marginLeft: '20px' }}>
        Engagement Over Time
      </TextView>
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 2 }}>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#086BDE', mr: 1 }} />
        <TextView $fontSize={12} $fontWeight={400}>
          Total Messages
        </TextView>
      </Stack>
      <ResponsiveContainer width="97%" height="80%">
        <LineChart data={values}>
          <YAxis tick={(props) => CustomizedYAxisTick(props)} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <XAxis
            dataKey="timestamp"
            height={60}
            interval={0}
            tick={(props) => CustomizedAxisTick(props)}
          />
          <Line type="monotone" dataKey="messages" stroke="#086BDE" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EngagementOverTime;
