import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { CommonCard, TotalMessageChart, AccountPerformanceTable } from './particles';
import { Grid, Box, Stack } from '@mui/material';

import { callGetCompanyEngagement, callGetTotalUsers, callGetCompanyAccountTypeCount } from '@api';
import { EngagementOverTime, DashboardHeader, PieChart } from '@organisms';

const NegotiatorDashboard: React.FC<RouteComponentProps> = () => {
  const [start, setStart] = useState<Date>(DateTime.local().minus({ days: 6 }).toJSDate());
  const [end, setEnd] = useState<Date>(new Date());

  const [companyEngagement, setCompanyEngagement] = useState<Engagement[]>([]);
  const [activeInactive, setActiveInactive] = useState<ActiveInactive>({ active: 0, inactive: 0 });
  const [accountTypeCount, setAccountTypeCount] = useState<AccountTypeCount>({ Trial: 0, Paid: 0 });

  const [loadingCompanyEngagement, setLoadingCompanyEngagement] = useState<boolean>(false);
  const [loadingCompanyAccountTypeCount, setLoadingCompanyAccountTypeCount] =
    useState<boolean>(false);
  const [loadingTotalUsers, setLoadingTotalUsers] = useState<boolean>(false);

  useEffect(() => {
    apiCalling();
  }, [start, end]);

  const apiCalling = async () => {
    if (start && end) {
      const startDate = DateTime.fromJSDate(start).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      const endDate = DateTime.fromJSDate(end).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });

      setLoadingCompanyEngagement(true);
      callGetCompanyEngagement({
        from: startDate!,
        to: endDate!
      })
        .then((res) => {
          if (res.data) {
            setCompanyEngagement(res.data);
          }
          setLoadingCompanyEngagement(false);
        })
        .catch((err) => {
          setLoadingCompanyEngagement(false);
        });

      setLoadingCompanyAccountTypeCount(true);
      callGetCompanyAccountTypeCount()
        .then((res) => {
          if (res.code === 200 && res.data) {
            setAccountTypeCount(res.data[0]);
          }
          setLoadingCompanyAccountTypeCount(false);
        })
        .catch((err) => {
          setLoadingCompanyAccountTypeCount(false);
        });

      setLoadingTotalUsers(true);
      callGetTotalUsers({})
        .then((res) => {
          if (res.code === 200 && res.data) {
            const total = res.data.data.total;
            setActiveInactive(total);
          }
          setLoadingTotalUsers(false);
        })
        .catch((err) => {
          setLoadingTotalUsers(false);
        });
    }
  };

  return (
    <Stack sx={{ m: 2 }}>
      <Box sx={{ mt: 1, mb: 2 }}>
        <DashboardHeader
          title={'Dashboard'}
          csvDownload={true}
          start={start}
          end={end}
          onSelectRange={(start, end) => {
            setStart(start);
            setEnd(end);
          }}
        />
      </Box>

      <CommonCard
        loading={loadingCompanyEngagement || loadingTotalUsers}
        users={activeInactive.active + activeInactive.inactive}
        accounts={companyEngagement.length}
        actives={activeInactive.active ?? 0}
      />

      <Grid container columnSpacing={2} mt={2}>
        {/* <Grid md={4} sm={12} item>
          <PieChart
            title={'Total Active Accounts'}
            title1={'Paid'}
            title2={'Trial'}
            value1={accountTypeCount.Paid}
            value2={accountTypeCount.Trial}
          />
        </Grid> */}
        <Grid md={12} sm={12} item>
          <EngagementOverTime start={start} end={end} />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, mb: 2 }}>
        <AccountPerformanceTable start={start} end={end} />
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <TotalMessageChart
          loading={loadingCompanyEngagement}
          companyEngagement={companyEngagement}
        />
      </Box>
    </Stack>
  );
};

export default NegotiatorDashboard;
